.ltr{
    .apply-btn {
        background: #00c5fb;
        background: linear-gradient(to right, #ff9b44 0%, #fc6075 100%);
        border-color: transparent;
        box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
        color: #fff;
        position: absolute;
        right: 20px;
        top: 20px;
    }
    .form-control:focus {
        border-color: #ff9b44;
        box-shadow: none;
    }
    .btn:first-child:active, :not(.btn-check)+.btn:active {
        border-color: #cccccc;
    }
    .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
        border-color: #cccccc;
    }
    .input-group>.form-control:focus, .input-group>.form-floating:focus-within, .input-group>.form-select:focus {
        z-index: 3;
    }
    .page-item.disabled .page-link {
        background-color: $white;
    }
    .personal-info li .text .avatar-box {
        position: relative;
        top: 0;
    }
    .chat-footer .message-bar .message-area .btn:hover {
        border-color: transparent;
    }
    .chat-footer .message-bar .message-area .btn i {
        color: $white;
    }
    .btn.btn-assign:hover,.btn.btn-assign:active {
        border-color: #ff9b44;
    }
    .btn.btn-assign:active {
        background-color: #ff9b44;
    }
    
    .btn.add-btn:active {
        background: $primary;
        color: $white
    }
    .header .has-arrow .dropdown-toggle.job-view-flag:after {
        border-bottom: 2px solid $secondary-900;
        border-right: 2px solid $secondary-900;
    }
    .top-nav-search .form-control.job-view-search::-moz-placeholder {
        color: $secondary-900 !important;
    }
    
    table {
        td {
            a {
                &.download-offer {
                    // color: $white;
                    &:hover {
                        color: $white;
                    }
                }
            }
            .dropdown {
                &.action-label {
                    a {
                        &.btn-rounded {
                            &.dropdown-toggle {
                                display: inline-flex;
                                align-items:center;
                                gap: 5px;
                                &::after {
                                    margin-left: 0;
                                }
                            }
                        } 
                    }
                }
            }
        }
    }
    .comp-section {
        .card-title {
            margin-bottom: 0.5rem;
        }
        .pagination {
            margin-bottom: 1rem;
            flex-wrap: wrap;
        }
    }
    .setting-pref-btn {
        height:44px;
        font-size: 16px;
        padding:10px 15px;
    }
    .offcanvas-footer {
        button {
            &.btn-light {
                &:hover {
                    color: #000;
                    background-color: #f9fafb;
                }
            }
        }
    }
    .files-cont {
        .files-action {
            .dropdown-action {
                a {
                    &:hover {
                        border-color:transparent;
                    }
                }
            }
        }
    }
    .form-check-input:focus {
        box-shadow: none;
        border-color: #dee2e6;
    }
    .custom_check {
        display: inline-block;
        position: relative;
        font-size: 14px !important;
        margin-bottom: 15px;
        padding-left: 0;
        cursor: pointer;
        font-weight: 400 !important;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        line-height: 18px;
    }
    .custom_check input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .custom_check input:checked ~ .checkmark {
        background-color: #ff9b44;
        border-color: #ff9b44;
    }
    .custom_check .checkmark {
        position: absolute;
        top: 3px;
        left: -6px;
        height: 18px;
        width: 18px;
        border: 1px solid #E4E4E8;
        background-color: #fff;
        border-radius: 5px;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    .custom_check input:checked ~ .checkmark:after {
        display: block;
    }
    .custom_check .checkmark::after {
        content: "\f00c";
        font-family: 'Font Awesome 6 Free' !important;
        font-weight: 900;
        position: absolute;
        display: none;
        left: 3px;
        top: 50%;
        transform: translateY(-50%);
        color: #ffffff;
        font-size: 11px;
    }
    .continue-btn:focus, .cancel-btn:focus {
        color: $white !important;
    }
    .btn-info.download-offer,.btn.btn-info {
        color: $white;
    }
    .swal2-confirm {
        margin-right: 10px;
    }
    .select2-container {
        min-width: 100% !important;
        width: auto !important;
        z-index: 99;
        padding: 0 !important;
    }
    .btn.btn-outline-primary {
        border-color: $primary;
    }
    .nav-pills {
        .nav-link {
            color: $secondary;
            &.active {
                background: $primary;
                color: $white;
            }
        }
    }
    .card-buttons {
        .card-link {
            color: $primary;
        }
    }
    ul {
        &.bullets {
            padding-left: 20px;
            li {
                list-style-type: disc;
            }
        }
    }
    .card {
        ol {
            padding-left: 20px;
        }
    }
    .progress-example {
        .progress  {
            .progress-bar {
                background-color: $primary ;
            }
        }
    }
    .btn-list button {
        margin: 0 0.375rem 0.375rem 0;
    }
    .btn.btn-teal {
        background-color: #02a8b5 !important;
        border: 1px solid #02a8b5 !important;
        color: #ffffff;
        box-shadow: 0 4px 20px rgba(2, 168, 181, 0.15);
    }
    .btn.btn-purple {
        background-color: #7367F0 !important;
        border: 1px solid #7367F0 !important;
        color: #ffffff;
        box-shadow: 0 4px 20px rgba(115, 103, 240, 0.15);
        &:hover {
            background: $primary;
            color: $white-hover;
        }
    }
    .btn-soft-primary {
        background: rgba($primary,0.4);
        color: $primary;
        &:hover {
            background: $primary;
            color: $white;
        }
    }
    .btn-soft-success {
        background: rgba($success,0.4);
        color: $success;
        &:hover {
            background: $success;
            color: $white;
        }
    }
    .btn-soft-secondary {
        background: rgba($secondary,0.4);
        color: $secondary;
        &:hover {
            background: $secondary;
            color: $white;
        }
    }
    .btn-soft-warning {
        background: rgba($warning,0.4);
        color: $warning;
        &:hover {
            background: $warning;
            color: $white;
        }
    }
    .btn-soft-danger {
        background: rgba($danger,0.4);
        color: $danger;
        &:hover {
            background: $danger;
            color: $white;
        }
    }
    .btn-soft-info {
        background: rgba($info,0.4);
        color: $info;
        &:hover {
            background: $info;
            color: $white;
        }
    }
    .btn-soft-light {
        background: rgba($secondary-200,0.4);
        color: $secondary-200;
        &:hover {
            background: $secondary-200;
            color: $white;
        }
    }
    .btn-soft-dark {
        background: rgba($black,0.4);
        color: $black;
        &:hover {
            background: $black;
            color: $white;
        }
    }
    .btn-group.btn-group-pill {
        border-radius: 50px;
    }
    .btn-group-pill .btn:first-child {
        border-radius: 60px 0 0 60px;
    }
    .btn-group-pill .btn:last-child {
        border-radius: 0 60px 60px 0;
    }
    .btn-warning {
        color: $white;
    }
    .btn-check:checked + .btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active {
        background-color: #FF9F43;
        border-color: #FF9F43;
        color: #ffffff !important;
    }
    .btn-check+.btn:hover {
        background-color: #FF9F43;
        border-color: #FF9F43;
        color: #ffffff !important;
    }
    .button-group-input {
        input {
            height: 46px;
        }
    }    
}