/****** Utils ******/
@import "utils/variables";
@import "utils/mixins";

/******* Base *******/
@import "base/typography";
@import "base/base";

/******* Components ******/
@import "components/alerts";
@import "components/avatar";
@import "components/accordion";
@import "components/badge";
@import "components/border";
@import "components/background";
@import "components/bootstrap-classes";
@import "components/breadcrumb";
@import "components/button";
@import "components/cards";
@import "components/chart";
@import "components/dropdown";
@import "components/grid";
@import "components/nav-tab";
@import "components/pagination";
@import "components/popup";
@import "components/popover";
@import "components/progress";
@import "components/ribbon";
@import "components/tables";
@import "components/tooltips";
@import "components/timeline";

/******* Vendors ******/
@import "vendors/select2";

/****** Layout ******/
@import "layout/content";
@import "layout/footer";
@import "layout/header";
@import "layout/sidebar";
@import "layout/theme-settings";

/****** Pages ******/
@import "pages/activites";
@import "pages/assets";
@import "pages/attendance";
@import "pages/chat";
@import "pages/call";
@import "pages/calendar";
@import "pages/contact";
@import "pages/components";
@import "pages/email";
@import "pages/error";
@import "pages/dashboard";
@import "pages/filemanager";
@import "pages/login";
@import "pages/loader";
@import "pages/faq";
@import "pages/employee";
@import "pages/project";
@import "pages/task";
@import "pages/page";
@import "pages/profile";
@import "pages/payroll";
@import "pages/knowledge";
@import "pages/subscriptions";
@import "pages/notification";
@import "pages/file-upload";
@import "pages/alertify";
@import "pages/form-wizard";
@import "pages/deals";
@import "pages/analytics";
@import "pages/tickets";
@import "pages/deals-dashboard";