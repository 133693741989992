.rtl{
    #loader-wrapper {
        background-color: $white;
        height: 100%;
        width: 100%;
        z-index: 9999;
        @include position(fixed,0,0,0,0);
        #loader {
            background-position: center center;
            background-repeat: no-repeat;
            height: 200px;
            margin: -100px 0 0 -100px;
            width: 200px;
            @include position(absolute,50%,null,null,50%);
            .loader-ellips {
                font-size: $font-size-20;
                position: relative;
                width: 64px;
                margin: 100px auto 0;
                .loader-ellips__dot{
                    display: block;
                    width: 1em;
                    height: 1em;
                    position: absolute;
                    @include rounded(0.5em);
                    animation-duration: 0.5s;
                    animation-timing-function: ease;
                    animation-iteration-count: infinite;
                    &:nth-child(1) { 
                        background: $secondary-900;  
                        animation-name: reveal;
                        left: 0;
                    }
                    &:nth-child(2) { 
                        background: $primary; 
                        left: 0;
                        animation-name: slide; 
                    }
                    &:nth-child(3) { 
                        background: $secondary-900;  
                        animation-name: slide;
                        left: 1.5em;
                    }
                    &:nth-child(4) { 
                        background: $secondary-900;  
                        animation-name: reveal;
                        animation-direction: reverse;
                        left: 3em;
                    }
                }
            }
        }
    }
    @keyframes reveal {
        from { 
            transform: scale(0.001); 
        }
        to { 
            transform: scale(1); 
        }
    }
    @keyframes slide {
        to { 
            transform: translateX(1.5em) 
        }
    }
}