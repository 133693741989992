.rtl{
    .project-title {
        @include margin-padding(0 0 5px, null);
        a {
            color: $secondary-900;
        }
    }
    .team-members {
        padding: 0;
        margin: 0;
        list-style: none;
        flex-wrap: wrap;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        img {
            width: 100%;
        }
        a {
            border: 2px solid $white;
            display: block;
            height: 30px;
            overflow: hidden;
            width: 30px;
            @include rounded(100%);
        }
        .all-users {
            background-color: $danger;
            color: $white;
            font-size: $font-size-10;
            font-weight: $font-weight-bold;
            line-height: 30px;
            text-align: center;
        }
        
    }
    .profile-action {
        text-align: left;
        @include position(absolute,10px,null,null,5px);
    }
    .project-members {
        a {
            margin-right: -10px;
            margin-left: 0;
            position: relative;
            &:first-child {
                margin-right: 0;
            }  
        }
        .team-members {
            li {
                a {
                    margin-right: -10px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                }
                &:first-child {
                    a {
                        margin-right: 0;
                    }
                }
            }
        }
        &.task-followers {
            :nth-child(2) {
                margin-right: 0;
            }
        }
    }
    .pro-team-members {
        h4 {
            margin-bottom: 0;
        }
    }
    table {
        .team-members {
            li {
                a {
                    margin-right: -10px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                }
                &:first-child {
                    a {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .modal .modal-body .input-block {
        &.row {
            margin-right: auto;
        }
    }
    .header {
        .has-arrow {
            &.flag-nav {
                .dropdown-toggle {
                    &:after {
                        position: relative;
                        top: -2px;
                    }
                }
            }
        } 
    }
    .dropdown {
        .btn-rounded {
            display: inline-flex;
            align-items: center;
            gap: 5px;
        }
    }
    .table-avatar {
        a {
            &.avatar {
                line-height: normal;
            }
        } 
    }
    .progress-bar {
        &.w-40 {
            width: 40%;
        } 
        &.w-65 {
            width: 65%;
        }
        &.w-15 {
            width: 15%;
        }
        &.w-88 {
            width: 88%;
        }
        &.w-70 {
            width: 70%;
        }
        &.w-31 {
            width: 31%;
        }
        &.w-62 {
            width: 62%;
        }
        &.w-22 {
            width: 22%;
        }
        &.w-24 {
            width: 24%;
        }
        &.w-30 {
            width: 30%;
        }
        &.w-26 {
            width: 26%;
        }
        &.w-10 {
            width: 10%;
        }
        &.w-21 {
            width: 21%;
        }
        &.w-20 {
            width: 20%;
        }
    }
    th {
        &.width-pixel {
            width: 40px;
        }
    }
    .btn-close:focus {
        box-shadow: none;
    }
    .progress {
        &.height-five {
            height: 5px;
        }
    }
    textarea {
        &.form-control {
            height: auto;
        }
    }
    td {
        &.tdata-width {
            width: 230px;
        }
    }
    th {
        &.width-thirty {
            width: 30px;
        }
    }
    .badge.min-w-90 {
        min-width: 90px;
    }
    td.w-50pixel {
        width: 50px;
    }
    td.w-300pixel {
        width: 300px;
    }
    .progress.height-5 {
        height: 5px;
    }
    .progress-bar.w-73 {
        width: 73%;
    }
    .progress-bar.height-10 {
        height: 10px;
    }
    .form-control.width-160 {
        width: 160px;
    }
    thead .bg-thead {
        background:#f2f2f2;
    }
    th.width-250 {
        width: 250px;
    }
    th.width-70 {
        width: 70px;
    }
    td.width-55 {
        min-width:55px;
        max-width:55px;
        width:55px;
    }
    a.green-border {
        border:2px dashed #1eb53a
    }
    th.width-64 {
        width: 64px;
    }
}