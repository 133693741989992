body.rtl .horizontalLayout {
    .sidebar {
        width: 100% !important;
        bottom: inherit;

        .sidebar-menu ul li span {
            display: inline-block !important;
        }

        .slimScrollDiv,
        .sidebar-inner {
            height: auto !important;
            overflow: inherit !important;
        }

        .hidden {
            display: none !important;
        }

        .sidebar-menu {
            padding: 0 !important;
            height: 60px;
            display: flex;
            align-items: center;
        }

        .sidebar-vertical {
            display: none !important;
        }

        .sidebar-horizantal {
            display: flex !important;
        }

        .sidebar-menu ul li a {
            flex-direction: inherit;
            text-align: left;
        }

        .sidebar-menu .menu-title {
            display: none !important;
        }

        ul li span {
            display: inline-block !important;
        }

        .hidden-links {
            .submenu {
                ul {
                    box-shadow: 0 5px 4px rgba(0, 0, 0, 0.2);
                    border: 1px solid #d5d5d5;
                }
            }

            li {
                a {
                    &:hover {
                        color: $secondary-900;
                    }
                }

            }
        }

        .sidebar-menu {
            ul {
                display: flex;
                align-items: center;
                -webkit-align-items: center;

                li {
                    &.submenu {
                        .noti-dot {
                            &:before {
                                display: none;
                            }
                        }
                    }

                    a {
                        .menu-arrow {
                            right: 0;
                        }
                    }

                }

                ul {
                    display: none;
                    width: 200px;
                    background-color: $secondary-800;
                    @include position(absolute, 45px, null, null, 0);

                    li {
                        a {
                            .menu-arrow {
                                right: 15px;
                            }
                        }
                    }

                    a {
                        padding: 8px 15px;
                    }
                }
            }
        }

        .list-inline-item {
            li {
                a:hover {
                    color: $white;
                }
            }
        }
    }

    #sidebar-view,
    #sidebar-size,
    #layout-width,
    #layout-position {
        display: none;
    }

    #toggle_btn {
        display: none;
    }

    ul.hidden-links {
        position: absolute !important;
        right: 0;
        background: $white;
        width: 100%;
        top: 60px;
        justify-content: start;
        align-items: start !important;
        flex-wrap: wrap;
        border-top: 1px dashed $light-900 ;
        padding: 12px 3px;
        box-shadow: 0 5px 4px rgb(0 0 0 / 20%);

        ul {
            left: 0 !important;
            position: absolute !important;
            top: 40px !important;
            background-color: $white !important;
            width: 100% !important;
            z-index: 9999;

            li {
                a {
                    width: 100%;

                    &:hover {
                        color: $secondary-900;
                    }
                }
            }
        }

        >li {
            width: 20%;

            a {
                display: block;
                padding: 5px 15px;
            }
        }

        li a .menu-arrow {
            right: 10px !important;
        }
    }

    .sidebar .sidebar-menu ul li a {
        padding: 10px 15px;
    }

    .greedy ul.list-inline-item.list-unstyled.links {
        width: 95%;

    }

    .greedy {
        >button {
            display: block;
        }
    }

    nav.greedy button {
        align-self: stretch;
        transition: all .4s ease-out;
        padding: 5px 0 0 5px;
        outline: 0;
        border: 0;
        font-size: 14px;
        font-weight: $font-weight-bold;
        background-color: transparent;
        color: $white;
        min-width: 100px;
        margin-right: 20px;
    }

    nav.greedy {
        position: relative;
        height: 45px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        justify-content: start;
        -webkit-justify-content: start;
        -ms-flex-pack: start;
    }

    nav.greedy ul.hidden-links.hidden {
        display: none;
    }

    .page-wrapper {
        margin: 0;
        padding-top: 120px;
    }

    .sidebar .sidebar-menu ul ul ul {
        position: absolute;
        right: -200px;
        top: 0;
        left: auto;
    }

    .sidebar ul ul li {
        display: inline-block !important;
        width: 100%;
    }

    .hidden-links {
        .menu-title {
            display: inline-block !important;
            color: $secondary-900 !important;
        }
    }
}

body.rtl .orangeScheme {
    .header {
        background: $primary;
        background: linear-gradient(to right, $primary 0%, #fc6075 100%);

        .header-left .logo {
            display: block;
            line-height: 60px;
        }

        .user-menu.nav>li>a {
            color: $white
        }

        #toggle_btn .bar-icon span {
            background-color: $white;
        }

        .page-title-box h3 {
            color: $white;
        }

        .header-left .logo2 {
            display: none;
        }

        .top-nav-search form {
            .form-control {
                background-color: $box-shadow;
                border: 0;
                color: $white;
                height: 40px;
                padding: 10px 50px 10px 15px;

                &::-webkit-input-placeholder {
                    color: $box-shadow-sm;
                }
            }

            .btn {
                color: $box-shadow-sm;
            }
        }
    }

    .sidebar-twocol.sidebar .sidebar-left {
        background-color: $secondary-800;
    }
}

body.rtl .lightScheme.darkColorTopbar {
    .header {
        background: #263238;

        .has-arrow .dropdown-toggle:after {
            border-bottom: 2px solid #fff;
            border-right: 2px solid #fff;
        }
    }
}

body.rtl .darkScheme {
    background-color: $body-dark-bg;
    color: $secondary-300;

    .stats-box {
        background-color: $black-bg;
        border: 1px solid $dark-border;
    }

    .card {
        color: $secondary-300;
        background-color: $body-dark-bg !important;

        .card-body {
            background-color: $body-dark-bg;
        }
    }

    .disabled>.page-link,
    .page-link.disabled {
        --bs-pagination-disabled-color: #6c757d;
    }

    .w-sidebar ul a {
        color: $secondary-300;
    }

    .card .card-title {
        color: $secondary-300;
    }

    &.mini-sidebar {
        .sidebar {
            background-color: #34444c;
        }
    }

    .header {
        .logo {
            display: block;
        }
    }

    .profile-view {
        .profile-basic .profile-info-left .user-name {
            color: #bbc4cc;
        }
    }

    .personal-info li .title {
        color: #bbc4cc;
    }

    .experience-box .experience-list li {
        .experience-content {
            background-color: #2e3840;
            padding: 10px;
        }

        .experience-user {
            border-radius: 50px;
        }
    }

    .sidebar-twocol.sidebar {
        .sidebar-left {
            background: $black-bg;

            .nav-link {
                color: #bbc4cc;
                background: #2e3840;

                &.active {
                    color: #2e3840;
                    background: #fff;
                }
            }
        }

        .sidebar-right {
            background: $dark-border;

            ul li a {
                color: $secondary-300;

                &.active {
                    color: $white;
                }
            }
        }
    }

    .dash-statistics .stats-info {
        background-color: $black-bg;
        border: 1px solid $dark-border;
    }

    table.table {
        background-color: $black-bg;
        border: 1px solid $dark-border;
        background: $black-bg;
        --bs-table-bg: $black-bg;

        th {
            color: $secondary-300;
            border-top: 1px solid #2e3840;
        }

        td {
            border-top: 1px solid transparent;
            color: $secondary-300;
        }

        .btn-white {
            background-color: $black-bg;
            border: 1px solid $dark-border;
        }
    }

    .chat-main-row .chat-main-wrapper .chat-window .fixed-header,
    .chat-footer {
        background-color: $black-bg;
        border-bottom: 1px solid $dark-border;
    }

    .video-window .fixed-header .nav li a {
        color: $white;
    }

    .sidebar .sidebar-menu ul li a {
        color: $secondary-300;
    }

    .two-col-bar .sidebar-menu ul li a {
        color: $secondary-300;
    }

    .sidebar ul li span {
        display: inline-block;
        color: $secondary-300;
    }

    .contact-list {
        >li {
            background-color: $black-bg;
            border-bottom: 1px solid $dark-border;
        }
    }

    .btn-white {
        background-color: $black-bg;
        border: 1px solid $dark-border;
        color: $white;
    }

    .page-item .page-link {
        background-color: $black-bg;
        border: 1px solid $dark-border;
    }

    .dataTables_length {
        .form-control {
            background-color: $black-bg;
            color: $white;
            border: 1px solid $dark-border;
        }
    }

    .table-striped tbody tr:nth-of-type(2n+1) {
        background-color: #2c3034;
    }

    .nav-tabs.nav-tabs-solid {
        background-color: $black-bg;
        border: 1px solid $dark-border;
    }

    .select2-container--default .select2-selection--single {
        background-color: $black-bg;
        border: 1px solid $dark-border;
    }

    .modal-body {
        .select2-container--default .select2-selection--single {
            background-color: transparent;
            border: 1px solid $light-900;
        }

        .bootstrap-tagsinput {
            background-color: transparent;
        }
    }

    .activity-box .activity-list li {
        background-color: $black-bg;
        border: 1px solid $dark-border;
    }

    .breadcrumb .breadcrumb-item a,
    .employee-field .img-reset-btn a {
        color: $secondary-300;
    }

    .breadcrumb-item.active {
        --bs-breadcrumb-item-active-color: #6c757d;
    }

    .page-wrapper .content .page-header .page-title {
        color: $secondary-300
    }

    .page-wrapper .content .page-header h5 {
        color: $secondary-300
    }

    .leave-info-box {
        border: 1px solid $dark-border;
    }

    .card-header {
        background-color: $black-bg;
        border-bottom: 1px solid $dark-border;
    }

    .faq-card {
        .card {
            .card-header {
                background-color: #2e3840;

                a {
                    color: $secondary-300;
                }
            }
        }
    }

    .custom-table td {
        color: $white !important;
    }

    .table td a {
        color: #777;
    }
}

body.rtl .darkScheme .header {
    background: $body-dark-bg;
    border-color: $dark-border;

    .user-menu.nav>li>a {
        color: $secondary-300;
    }

    #toggle_btn .bar-icon span {
        background-color: $secondary-300;
    }

    .page-title-box h3 {
        color: $secondary-300;
    }

    .logo2 {
        display: none;
    }
}

body.rtl .darkScheme .form-control {
    border: 1px solid $dark-border !important;
    background: $black-bg;
    color: $secondary-300 !important;

    &::-webkit-input-placeholder {
        /* Edge */
        color: #fff;
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #fff;
    }

    &::placeholder {
        color: #fff;
    }
}

body.rtl .boxedWidth,
.rtl .boxedWidth .header {
    max-width: 1300px;
    margin: auto;
}

body.rtl .darkColorTopbar {
    .header {
        background: $body-dark-bg;
        border-color: $dark-border;

        .header-left .logo {
            display: block;
            line-height: 60px;
        }

        .top-nav-search form {
            .form-control {
                border: 1px solid #bbc4cc;
                color: #bbc4cc;

                &::-webkit-input-placeholder {
                    /* Edge */
                    color: #bbc4cc;
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: #bbc4cc;
                }

                &::placeholder {
                    color: #bbc4cc;
                }
            }

            .btn {
                color: #bbc4cc;
            }
        }

        .user-menu.nav>li>a {
            color: $secondary-300;
        }

        #toggle_btn .bar-icon span {
            background-color: $secondary-300;
        }

        .page-title-box h3 {
            color: $secondary-300;
        }

        .logo2 {
            display: none;
        }
    }
}

body.rtl .lightSidebarColor {
    .sidebar-twocol.sidebar {
        .sidebar-right {
            background: #fff;

            ul li a.active {
                color: #333;
            }
        }

        .sidebar-left .nav-link {
            color: #34444c;
            background: #fff;

            &.active {
                color: #333333;
                background: #fff;
            }
        }
    }

    .sidebar {
        background-color: $white;
        box-shadow: 0 1px 1px 0 rgb(0 0 0 / 20%);

        ul li span {
            display: inline-block;
            color: $secondary-900;

            &:hover {
                display: inline-block !important;
            }
        }

        .sidebar-menu {
            padding: 10px 0;
            background: $white;
        }

        .list-inline-item li a:hover i {
            color: #333 !important;
        }
    }

    .greedys .viewmoremenu {
        color: #333;
    }

    nav.greedy button {
        color: #333 !important;
    }

    .sidebar-twocol.sidebar .sidebar-left {
        background: #fafafa;

        .nav-link {
            &.active {
                background: #34444c;
                color: #fff;
            }
        }
    }

    .mini-sidebar {
        &.expand-menu {
            .sidebar {
                ul li span {
                    display: block !important;
                }
            }
        }

        .sidebar {
            background-color: $white;

            ul li span {
                display: none !important;
            }
        }
    }

    nav.greedy button {
        color: $secondary-900;
    }

    .sidebar-menu ul li a:hover,
    .two-col-bar .sidebar-menu ul li a:hover {
        color: $secondary-900;
    }

    .sidebar .sidebar-menu ul li.active a,
    .two-col-bar .sidebar-menu ul li.active a {
        color: $secondary-900;
        background-color: transparent;
    }
}

body.rtl .boxedWidth,
.rtl .boxedWidth .header {
    max-width: 1300px;
    margin: auto;
}

body.rtl .darkScheme {
    .header {
        .user-menu {
            &.nav {
                &>li {
                    &.flag-nav {
                        .dropdown-menu {
                            background-color: #34444c;

                            .dropdown-item {
                                color: #bbc4cc;

                                &:hover {
                                    background: #bbc4cc;
                                    color: #34444c;
                                }
                            }
                        }
                    }
                }
            }
        }

        .dropdown-menu {
            .notification-title {
                color: #bbc4cc;
            }

            background-color: #34444c;

            .topnav-dropdown-header {
                border-bottom-color: #bdbdbd;
            }

            .notification-list {
                li {
                    border-bottom-color: #bdbdbd;

                    .noti-title {
                        color: #fff;
                    }

                    .list-item {
                        .list-body {
                            .message-author {
                                color: #fff;
                            }

                            .message-content {
                                color: #fff;
                            }
                        }
                    }

                }
            }

            .topnav-dropdown-footer a {
                color: #bbc4cc;
            }

            .dropdown-item {
                color: #bbc4cc;

                &:hover {
                    background: #bbc4cc;
                    color: #34444c;
                }
            }
        }
    }

    table.table {
        th {
            background-color: #34444c;
        }

        td {
            background-color: #34444c;
        }

    }

    .custom-modal {
        .modal-content {
            background-color: #34444c;
        }
    }

    .select2-container--default {
        .select2-results {
            &>.select2-results__options {
                background-color: #34444c;
            }

            .select2-results__option--highlighted[aria-selected] {
                background-color: #bbc4cc;
            }
        }
    }

    .dropdown {
        &.action-label {
            .dropdown-menu {
                background-color: #000;
            }
        }

        &.dropdown-action {
            .dropdown-menu {
                background-color: #000;

                .dropdown-item {
                    color: #bbc4cc !important;

                    &:hover {
                        background: #bbc4cc;
                        color: #34444c !important;
                    }
                }
            }
        }
    }

    .card-table .card-footer a {
        color: #bbc4cc;
    }

    .profile-view {
        .profile-img {
            background-color: #34444c;
        }

        .profile-img-wrap {
            border-radius: 50%;
        }
    }

    .bootstrap-datetimepicker-widget {
        background-color: #34444c;
        color: #bbc4cc
    }

    .custom-modal {
        .modal-content {
            .modal-body {
                .chat-user-list {
                    .chat-block {
                        background-color: transparent;

                        .user-name {
                            color: #bbc4cc;
                        }
                    }
                }
            }
        }
    }

    .list-body .message-author {
        color: #bbc4cc;
    }

    .list-body .message-content {
        color: #bbc4cc;
    }
}

body.rtl .darkScheme {
    .project-slider .project-grid {
        background: $body-dark-bg;
        border-color: $black;

        .project-top h5 a {
            color: $secondary-300;
        }

        .project-top p {
            color: $secondary-100;
        }

        .project-bottom .project-leader .nav li {
            color: $secondary-300;
        }

        .project-middle {
            background: #4D5154;

            .project-tasks h4 {
                color: $secondary-300;
            }

            .project-tasks p {
                color: $secondary-100;
            }
        }
    }

    .employee-month-card .employee-month-details h4 {
        color: $secondary-300;
    }

    .clock-in-list .nav li {
        background: #4D5154;

        h6 {
            color: $secondary-100;
        }
    }
}

body.rtl.dark-select .darkScheme {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: $secondary-300;
    }

    .ticket-detail-head {
        .ticket-head-card {
            background: $body-dark-bg;
            border-color: $body-dark-bg;
        }
    }

    .ticket-purpose {
        background: $body-dark-bg;
        border-color: $black;

        ul {
            li {
                color: $secondary-300;
            }
        }
    }

    .attached-files-info .attached-files {
        background: $body-dark-bg;
        border-color: $black;

        ul {
            li {
                p {
                    color: $secondary-300;
                }

                .file-download {
                    a {
                        color: $secondary-300;
                    }
                }
            }
        }
    }

    .ticket-chat {
        background: $body-dark-bg;
        border-color: $black;

        .ticket-chat-head {
            .chat-post-box {
                background: $body-dark-bg;
                border-color: $black;
            }
        }
    }
}

body.rtl .darkScheme {
    .accordion-body {
        background: $secondary-800;
        color: $secondary-300;
    }
}

body.rtl .darkScheme {

    .custom-alert-icon,
    .custom-alert1 {
        background: #2b2f32;
    }
}

body.rtl .horizontalLayout {
    .sidebar {
        .sidebar-menu {
            ul {
                li {
                    &.submenu {
                        .noti-dot {
                            &::after {
                                display: none;
                            }
                        }
                    }
                }

                ul {
                    li {
                        a {
                            padding: 10px 25px;
                        }

                        &.submenu {
                            ul {
                                li {
                                    a {
                                        padding-left: 25px;

                                        &::before {
                                            left: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &.hidden-links {
                    li {
                        a {
                            &:hover {
                                color: $primary;
                            }
                        }
                    }
                }
            }
        }
    }
}

body.rtl .darkScheme {
    .alertify {
        .ajs-dialog {
            background-color: #313533;
        }

        .ajs-body {
            color: #adb5bd;

            .ajs-content {
                .ajs-input {
                    color: #adb5bd;
                    background-color: #363a38;
                    border: 1px solid #3b403d;
                }
            }
        }

        .ajs-footer {
            .ajs-buttons {
                .ajs-button {
                    color: #adb5bd;

                    &.ajs-ok {
                        color: $primary;
                    }
                }
            }
        }
    }
}

body.rtl .darkScheme .alertify .ajs-footer,
body.rtl .darkScheme .alertify .ajs-header {
    color: #ced4da;
    background-color: #313533;
    border-color: #3b403d;
}

body.rtl .horizontalLayout {
    &.header-collapse {
        .header {
            display: none;
        }

        .page-wrapper {
            padding-top: 50px;
        }

        .sidebar,
        .two-col-bar {
            top: 0;
        }
    }
}

body.rtl.dark-select .darkScheme .header .logo {
    display: block;
}

body.rtl.dark-select .darkScheme .header .header-left .logo2 {
    display: none;
}

.rtl{
    .main-wrapper.detachedSidebarView.horizontalLayout {
        max-width: 100%;
        padding: 0;

        .sidebar {
            top: 60px;
            border-radius: 0;
            left: 0;
            position: relative;
        }
    }

    .sm-hover.twocolumnLayout,
    .smallHoverSidebarSize.twocolumnLayout {
        .page-wrapper {
            margin: 0;

            .sidebar {
                .sidebar-left {
                    display: block;
                }

                .sidebar-right {
                    display: none;
                }
            }
        }
    }

    .twocolumnLayout {
        .two-col-bar {
            display: block;
        }

        #sidebar {
            display: none;

            @include respond-below(custom991) {
                display: block;
            }
        }

        #sidebar-view,
        #sidebar-size,
        #layout-width,
        #layout-position {
            display: none;
        }

        .mini-sidebar {
            .sidebar {
                .sidebar-right {
                    display: none;
                }
            }

            &.expand-menu {
                .sidebar {
                    .sidebar-right {
                        display: block;
                    }
                }
            }
        }

    }

    .orangeScheme.defaultColorTopbar {
        body {
            .header {
                .has-arrow .dropdown-toggle:after {
                    border-bottom: 2px solid #fff;
                    border-right: 2px solid #fff;
                }
            }
        }

    }

    .orangeScheme.darkColorTopbar {
        body {
            .header {
                background: #263238;

                .has-arrow .dropdown-toggle:after {
                    border-bottom: 2px solid #fff;
                    border-right: 2px solid #fff;
                }
            }
        }

    }

    .lightScheme .header {
        background: $white;
        border-color: transparent;

        .page-title-box h3 {
            color: $secondary-900;
        }

        .logo {
            display: none;
        }

        .logo2 {
            display: inline-block;
            line-height: 60px;
        }

        #toggle_btn .bar-icon span {
            background-color: $secondary-900;
        }

        .top-nav-search form {
            .form-control {
                border: 1px solid $secondary-200;
                color: $secondary-900;
                height: 40px;
                padding: 10px 50px 10px 15px;
                border-radius: 5px;

                &::-webkit-input-placeholder {
                    /* Edge */
                    color: $secondary-900;
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: $secondary-900;
                }

                &::placeholder {
                    color: $secondary-900;
                }
            }

            .btn {
                color: $secondary-900;
            }
        }

        .has-arrow {
            .dropdown-toggle:after {
                border-bottom: 2px solid $secondary-900;
                border-right: 2px solid $secondary-900;
            }
        }
    }

    .lightScheme nav.greedy button {
        color: $secondary-900 !important;
    }

    .lightScheme .sidebar-twocol.sidebar .sidebar-left {
        background: $white;
        border-right: 1px solid $secondary-200;

        .nav-link {
            color: $secondary-900;

            &.active {
                background: $secondary-800;
                color: $white;
            }
        }
    }

    .lightScheme .sidebar {
        background-color: $white;
        box-shadow: 0 1px 1px 0 rgb(0 0 0 / 20%);

        .sidebar-menu {
            ul {
                ul {
                    display: none;
                    width: 200px;
                }

                li {
                    a:hover {
                        color: $secondary-800;
                    }
                }
            }

            .greedy {
                ul {
                    li {
                        a:hover {
                            color: $secondary-800;
                        }
                    }
                }
            }
        }

    }

    .lightScheme .user-menu.nav>li>a {
        color: $secondary-900;
    }

    .lightScheme .mini-sidebar .header-left .logo2 img {
        height: auto;
        max-height: 30px;
        width: auto;
    }

    .lightScheme .header .header-left .logo {
        display: none !important;
        line-height: 60px;
    }

    .lightScheme .sidebar-menu ul li a:hover,
    .lightScheme .two-col-bar .sidebar-menu ul li a:hover {
        color: $secondary-900;
    }

    .lightScheme.darkSidebarColor {
        nav.greedy button {
            color: #fff !important;
        }
    }

    .darkScheme .border {
        border: 1px solid $black-bg !important;
    }

    .darkScheme .bg-light {
        background-color: $secondary-800 !important;
    }

    .darkScheme .nav-link {
        &:focus {
            color: $secondary-600;
        }
    }

    .darkScheme .nav-tabs .dropdown-menu {
        background-color: $secondary-800;
    }

    .darkScheme .nav-pills .dropdown-menu {
        background-color: $secondary-800;

        .dropdown-item {
            color: $secondary-600;
        }
    }

    .darkScheme .grid-showcase {
        span {
            color: $secondary-300;
            background-color: $secondary-800;

            &.bg-white {
                background-color: $body-dark-bg !important;

                &.text-dark {
                    color: $secondary-300;
                }
            }
        }

        .bg-white {
            background-color: $body-dark-bg !important;

            &.text-dark {
                color: $secondary-300 !important;
            }
        }

        .grid-wrapper {
            color: $secondary-300;
            background-color: $secondary-800;
        }
    }

    .darkScheme hr {
        color: $black-bg;
    }

    .darkScheme .modal-content {
        background-color: $body-dark-bg;

        .modal-header {
            border-bottom: 1px solid $dark-border;
        }

        .modal-footer {
            border-top: 1px solid $dark-border;
        }
    }

    .darkScheme .nav-tabs .nav-item.show .nav-link,
    .darkScheme .nav-tabs .nav-link.active {
        background-color: $secondary-800;
        color: $secondary-300;
    }

    .darkScheme .priority-info .dropdown .dropdown-menu {
        background-color: $black-bg;
    }

    .darkScheme .action-drop .dropdown-toggle {
        color: $black-bg !important;
    }

    .darkScheme .nav-tabs {
        .nav-link {

            &.active,
            &:hover {
                background-color: $secondary-800;
                color: $secondary-300;
            }
        }
    }

    .darkScheme .tab-style-6 {
        background-color: $secondary-800;
    }

    .darkScheme .nav.tab-style-1 {
        background-color: $secondary-800;
    }

    .darkScheme .tab-style-2 {
        .nav-item .nav-link {
            i {
                background-color: $secondary-800;
            }
        }
    }

    .darkScheme .tab-style-3 .nav-item .nav-link:after {
        background-color: $secondary-800;
    }

    .darkScheme .vertical-tabs-2 .nav-item .nav-link.active:before {
        background-color: $secondary-800;
    }

    .darkScheme .vertical-tabs-3 .nav-item .nav-link.active {
        background-color: $primary;
        color: $white;
    }

    .darkScheme .vertical-tabs-2 .nav-item .nav-link {
        border-color: $secondary-800;

        .active {
            border-color: $secondary-800;

            &::before {
                background-color: $secondary-800;
            }
        }
    }

    .darkScheme .pagination-style-2 .pagination .page-item.active .page-link {
        background-color: $black-bg;
    }

    .darkScheme .nav-tabs.nav-tabs-solid li a.active {
        border-color: $secondary-800;
    }

    .darkScheme .popover {
        border-color: $black-bg;

        .popover-header {
            background-color: $black-bg;
        }

        .popover-body {
            background-color: $body-dark-bg;
            color: $secondary-300;
        }
    }

    .darkScheme .figure-caption {
        color: $secondary-300;
    }

    .darkScheme .view-icons .btn {
        background-color: $black-bg;
        border: 1px solid $dark-border;
        color: $secondary-300;
    }

    .darkScheme .due-info,
    .darkScheme .assigned-info {
        color: $white;
    }

    .darkScheme .task-wrapper {
        .task-list-body {
            #task-list li {
                .task-container {
                    background: $body-dark-bg ;
                    border: 1px solid $dark-border;

                    .task-label {
                        color: $secondary-300;
                    }
                }

                &.completed .task-container {
                    background: $black-bg;
                }
            }
        }
    }

    .darkScheme .task-chat-contents,
    .darkScheme .people-select-tab {
        background-color: $body-dark-bg;
    }

    .darkScheme .sidebar {
        background-color: $black-bg;
    }

    .darkScheme .welcome-box {
        background-color: $black-bg;
        border-bottom: 1px solid $dark-border;

    }

    .darkScheme .chat-contents {
        .chat-content-wrap {
            .chats {
                .chat-right {
                    .chat-body .chat-content {
                        background-color: $black-bg;
                        border: 1px solid $dark-border;
                    }
                }

                .chat-left .chat-content {
                    background-color: $black-bg;
                    border: 1px solid $dark-border;
                    padding: 10px !important;

                    .chat-time {
                        color: $white;
                    }
                }
            }
        }
    }

    .darkScheme .chat-sidebar {
        .chat-contents {
            background-color: $body-dark-bg;
        }
    }

    .darkScheme .chat-footer {
        .message-bar {
            .message-area {
                .input-group .form-control {
                    background-color: $black-bg;
                    border: 1px solid $dark-border;
                }
            }
        }
    }

    .darkScheme .chat-line {
        .chat-date {
            background-color: $black-bg;
            top: 9px;
            left: -15px;
        }
    }

    .darkScheme .search-box {
        .input-group {
            background-color: $black-bg;
            border: 1px solid $dark-border;
        }
    }

    .darkScheme .chat-main-row {
        .chat-main-wrapper {
            .chat-window {
                background-color: transparent;
            }
        }
    }

    .darkScheme .dash-section .dash-info-list .dash-card {
        background-color: $black-bg;
        border: 1px solid $dark-border;
        color: #575757;
    }

    .darkScheme .card {
        border: 1px solid $black-bg;
        background: $black-bg;
    }

    .darkScheme .time-list .dash-stats-list h4 {
        color: $secondary-300;
    }

    .darkScheme .topics,
    .darkScheme .w-sidebar {
        background-color: $black-bg;
        border: 1px solid $dark-border;

        .topic-title a {
            color: $secondary-300;
        }

        .topics .topics-list li a {
            color: $secondary-300;
        }
    }

    .darkScheme .roles-menu ul {
        border: 1px solid $black-bg;
        background: $black-bg;

        li {
            a {
                color: $secondary-300;
            }
        }
    }

    .darkScheme .list-group-item,
    .darkScheme .activity-box,
    .darkScheme .punch-info .punch-hours,
    .darkScheme .punch-det,
    .darkScheme .att-statistics .stats-info,
    .darkScheme .stats-info {
        border: 1px solid $dark-border;
        background: $black-bg;
        color: $secondary-300;
    }

    .darkScheme .project-title a {
        color: #fff;
    }

    .darkScheme .select2-container--default {
        .select2-selection--single {
            .select2-selection__rendered {
                color: $secondary-300;
            }
        }
    }

    .darkScheme .profile-widget {
        border: 1px solid $dark-border;
        background: $black-bg;

        .user-name a {
            color: $white;
        }
    }

    .darkScheme .stats-info h6 {
        color: $secondary-300;
    }

    .darkScheme .people-select-tab label .people-profile a {
        color: $secondary-300;
    }

    .darkScheme .contacts-action .dropdown-toggle {
        color: $secondary-300;
    }

    .darkScheme .modal-body {
        .custom_radio {
            .form-control {
                color: $secondary-300;
            }
        }

        .form-control,
        .custom_radio {
            border-color: $light-900;
            box-shadow: none;
            background-color: transparent;
            color: $secondary-300;
        }
    }

    .darkScheme .chat-main-row .chat-main-wrapper .chat-sidebar {
        border-left: 1px solid $dark-border;
    }

    .darkScheme .nav-tabs {
        border-bottom: 1px solid $dark-border;
    }

    .darkScheme .chat-line {
        border-bottom: 1px solid $dark-border;
    }

    .darkScheme .file-wrap {
        .file-sidebar {
            .file-header {
                background-color: $black-bg;
                border-bottom: 1px solid $dark-border;
                color: $secondary-300;
            }

            .file-pro-list {
                background-color: $black-bg;

                .file-scroll .file-menu li a {
                    color: $secondary-300;
                }
            }
        }
    }

    .darkScheme .file-cont-wrap {
        .file-cont-inner .file-cont-header {
            background-color: $black-bg;
            border-bottom: 1px solid $dark-border;

            span {
                color: $secondary-300;
            }
        }
    }

    .darkScheme .file-content .file-body {
        background-color: $black-bg;

        .file-scroll .file-content-inner {
            padding: 15px;
            width: 100%;

            .card-file .card-file-thumb {
                background-color: $body-dark-bg;
            }
        }
    }

    .darkScheme .file-content .file-search {
        background-color: $body-dark-bg;
        border-bottom: 1px solid $dark-border;

        .form-control {
            background-color: $body-dark-bg;
            color: $secondary-300;
        }
    }

    .darkScheme .file-wrap .file-sidebar .file-search {
        background-color: $body-dark-bg;
        border-bottom: 1px solid $dark-border;

        .form-control {
            background-color: $black-bg;
        }
    }

    .darkScheme .file-cont-wrap .file-cont-inner .file-cont-header .file-options a {
        color: $secondary-300;
    }

    .darkScheme .file-wrap {
        border: 1px solid $dark-border;

        .file-sidebar {
            border-right: 1px solid $dark-border ;

            .file-search .input-group .form-control {
                color: $secondary-300;
                background-color: $body-dark-bg;
            }
        }
    }

    .darkScheme .table-bordered {
        td {
            border: 1px solid $dark-border;
        }
    }

    .darkScheme .nav-tabs.nav-tabs-bottom {
        li a {
            &.active {
                color: $white;
            }
        }
    }

    .darkScheme .offcanvas {
        background-color: $body-dark-bg;
        color: #bbc4cc;

        .sidebar-headerset {
            border-bottom: 1px solid $dark-border;
        }

        .settings-mains {
            .layout-head {
                h5 {
                    color: #ced4da;
                }

                h6 {
                    color: #ced4da;
                }
            }
        }

        .card-radio {
            .form-check-label {
                border-color: #ced4da;
            }
        }

        .offcanvas-footer {
            border-top: 1px solid $dark-border !important;
        }
    }

    .darkScheme .review-section .review-header {
        background-color: $body-dark-bg;
        border: 1px solid $dark-border;
    }

    .darkScheme .table-bordered th {
        border: 1px solid $dark-border;
    }

    .darkScheme .page-title {
        color: #bbc4cc;
    }

    .darkScheme .chat-contents .chat-content-wrap .chats .chat-bubble .chat-content .chat-time {
        color: #727272;
    }

    .darkScheme .col-form-label,
    .darkScheme .form-upload-profile h6 {
        color: $secondary-300;
    }

    .darkScheme .drag-upload {
        background-color: $body-dark-bg;
    }

    .darkScheme .upload-file {
        background-color: $body-dark-bg;

        h6 {
            color: $secondary-300;
        }
    }

    .darkScheme h1,
    .darkScheme h2,
    .darkScheme h3,
    .darkScheme h4,
    .darkScheme h5,
    .darkScheme h6 {
        color: $secondary-300;
    }

    .darkScheme .contact-sidebar {
        ul {
            border-color: $black-bg;
        }
    }

    .darkScheme .pipeline-list {
        border-color: $dark-border;
    }

    .darkScheme .contact-tab-wrap {
        background-color: $body-dark-bg;
    }

    .darkScheme .contact-tab-view {
        background-color: $body-dark-bg;
    }

    .darkScheme .contact-nav {
        li {
            a {
                border-color: $body-dark-bg;
                color: $secondary-300;
            }
        }
    }

    .darkScheme .pipeline-list ul {
        li a::after {
            -webkit-box-shadow: 2px -2px 0 1px $body-dark-bg, 3px -3px 0 2px rgba($body-dark-bg, 0.1);
            box-shadow: 2px -2px 0 1px $body-dark-bg, 3px -3px 0 2px rgba($body-dark-bg, 0.1);
        }
    }

    .darkScheme .set-info {
        li {
            a {
                color: $secondary-300;
            }
        }
    }

    .darkScheme .priority-info .dropdown {
        a {
            color: $secondary-300;
        }
    }

    .darkScheme .contact-wrap {
        color: $secondary-300;
        background-color: $body-dark-bg !important;

        .contact-profile {
            h4 {
                color: $secondary-300;
            }
        }
    }

    .darkScheme .notes-activity {
        .calls-box {
            h5 {
                color: $secondary-300;
            }

            .calls-user h6 {
                color: $secondary-300;
            }
        }

        .note-download {
            a {
                color: $secondary-300;
            }

            .note-info h6 {
                color: $secondary-300;
            }

            &:hover {
                a {
                    background-color: $black-bg;
                }
            }
        }

        .reply-box {
            background-color: #34444c;
        }
    }

    .darkScheme .badge-day,
    .darkScheme .form-sorts.dropdown {
        background-color: $black-bg;
    }

    .darkScheme .form-sorts.dropdown {
        background-color: $black-bg;

        a {
            color: $secondary-300;
        }

        .filter-dropdown-menu {
            background-color: $black-bg;
            border-color: $black-bg;

            ul {
                background-color: rgba($body-dark-bg, 0.9);
            }
        }
    }

    .darkScheme .search-set .search-input input[type=search] {
        background-color: $black-bg;
        color: $secondary-300;

        &::placeholder {
            color: $secondary-300;
        }
    }

    .darkScheme .title-head .form-sort .list-view {
        background-color: $black-bg;
        color: $secondary-300;
        border-color: $black-bg;
    }

    .darkScheme #filter_search:after {
        background: $black-bg;
    }

    .darkScheme td ul.social-links li {
        a {
            i {
                color: $secondary-300;
            }

            &:hover {
                i {
                    color: $black-bg;
                }
            }
        }
    }

    .darkScheme .calls-box .caller-info .calls-user p {
        span {
            color: $secondary-300;
        }
    }

    .darkScheme .calls-box .caller-info .calls-action {
        .dropdown-toggle {
            color: $secondary-300;
        }
    }

    .darkScheme .sync-radio .radio-item label {
        color: $secondary-300;
    }

    .darkScheme .signature-wrap,
    .darkScheme .form-upload-profile {
        background-color: $body-dark-bg;
    }

    .darkScheme .custom_check.check-box {
        color: $secondary-300;
    }

    .darkScheme .status-radio:checked+label,
    .darkScheme .status-radio:not(:checked)+label {
        color: $secondary-300;
    }

    .darkScheme .view-header,
    .darkScheme .activity-wrap,
    .darkScheme .calls-box,
    .darkScheme .files-wrap {
        border-color: $black-bg;
    }

    .darkScheme .view-header .form-sort .select2-container .select2-selection .select2-selection__rendered {
        color: $secondary-300;
    }

    .darkScheme .projects-info {
        li {
            a {
                background-color: $black-bg;
                color: $secondary-300;

                &:hover {
                    color: $secondary-300;
                }
            }
        }
    }

    .darkScheme .contact-grid {
        background-color: $body-dark-bg;
        border-color: $body-dark-bg;

        .grid-head .users-profile .name-user a {
            color: $secondary-300;
        }

        .grid-body .address-info span {
            color: $secondary-300;
        }

        .grid-footer .social-links li a {
            color: $secondary-300;
        }
    }

    .darkScheme .kanban-wrapper {
        .kanban-list-items {
            background-color: $body-dark-bg;
            border-color: $black-bg;

            .kanban-list-head {
                background-color: $body-dark-bg;
                border: 1px solid $black-bg;

                .kanban-title-head h5 {
                    color: $secondary-300;
                }
            }
        }
    }

    .darkScheme .kanban-card {
        background-color: $body-dark-bg;
        border-color: $black-bg;

        .kanban-card-head .kanban-card-title span {
            color: $secondary-300;
        }

        .kanban-card-body ul li {
            color: $secondary-300;
        }

        .kanban-card-footer ul li a {
            color: $secondary-300;

            &:hover {
                color: $black-bg;
            }
        }
    }

    .darkScheme .existing-company {
        background-color: $body-dark-bg;
    }

    .darkScheme .email-item li label {
        color: $secondary-300 !important;
    }

    .darkScheme .add-details-wizard ul {
        li {
            background: $body-dark-bg;
        }
    }

    .darkScheme .activities-list ul li {
        background: #34444c;

        .activity-name {

            h5,
            p {
                color: $secondary-300;
            }
        }
    }

    .darkScheme .card.analytics-card {
        border-color: $black-bg;

        .card-header {
            background-color: transparent;
        }

        .card-body {
            .table {
                th {
                    background-color: #34444c;
                    color: $secondary-300;

                    &:first-child {
                        border: 0;
                    }

                    &:last-child {
                        border: 0;
                    }
                }

                td {
                    &:first-child {
                        border: 0;
                    }

                    &:last-child {
                        border: 0;
                    }
                }
            }
        }
    }

    .darkScheme .clock-in-list .nav {
        border-color: $black-bg;
    }

    .darkScheme .attendance-list {
        border-color: $black-bg;
    }

    .darkScheme .statistic-header {
        h4 {
            color: $secondary-300;
        }

        .important-notification a {
            color: $secondary-300;
        }

        .statistic-dropdown .dropdown-toggle {
            color: $secondary-300;

            &::after {
                border-bottom: 2px solid $secondary-300;
                border-right: 2px solid $secondary-300;
            }
        }
    }

    .darkScheme .notification-tab .nav-tabs li {
        a {
            color: $secondary-300;
        }
    }

    .darkScheme .view-attendance a {
        color: $secondary-300;
    }

    .darkScheme .employee-noti-content .employee-notification-list .employee-notification-grid {
        .employee-notification-content h6 a {
            color: $secondary-300;
        }
    }

    .defaultColorTopbar .blueScheme {
        .header {
            background: $blue;
            background: $blue-gradient;
            border-color: transparent;

            .header-left .logo {
                display: block;
                line-height: 60px;
            }

            .user-menu.nav>li>a {
                color: $white;
            }

            #toggle_btn .bar-icon span {
                background-color: $white;
            }

            .page-title-box h3 {
                color: $white;
            }

            .logo2 {
                display: none !important;
            }
        }

        .header .has-arrow .dropdown-toggle:after {
            border-bottom: 2px solid #fff;
            border-right: 2px solid #fff;
        }

        .header .top-nav-search form .form-control {
            color: #fff;

            &::placeholder {
                color: #fff;
            }
        }

        .header .top-nav-search form .btn {
            color: #fff;
        }
    }

    .blueScheme {
        .header {
            background: $blue !important;
            background: $blue-gradient !important;
            border-color: transparent;

            .header-left .logo {
                display: block;
                line-height: 60px;
            }

            .user-menu.nav>li>a {
                color: $white !important;
            }

            #toggle_btn .bar-icon span {
                background-color: $white;
            }

            .page-title-box h3 {
                color: $white;
            }

            .logo2 {
                display: none;
            }
        }

        .page-item.active {
            .page-link {
                background-color: $blue ;
                border-color: $blue ;
            }
        }

        .nav-tabs.nav-tabs-solid {
            li a.active {
                background-color: $blue;
                border-color: $blue;
            }
        }

        .sidebar-twocol.sidebar {
            .sidebar-left {
                background: $secondary-800;
            }

            .sidebar-right ul li a.active {
                color: $blue;
            }
        }

        #layout-position .radio input:checked+label {
            background: $blue;
            color: $white;
        }

        .dash-widget {
            .card-body {
                .dash-widget-icon {
                    background-color: rgba(0, 197, 251, 0.2);
                    color: $blue;
                }
            }
        }

        .bg-primary,
        .badge-primary {
            background-color: $blue !important;
        }

        .sidebar {

            .sidebar-menu {
                ul ul a.active {
                    color: $blue !important;
                    text-decoration: underline;
                }
            }

            ul li.submenu .noti-dot:before {
                border: 5px solid $blue;
            }

        }

        .btn-primary {
            background-color: $blue;
            border: 1px solid $blue;

            &:focus,
            &:hover,
            &:active,
            &.active {
                color: $white;
                background-color: $blue-hover;
                border-color: $blue-hover;

                .open {
                    &>.dropdown-toggle {
                        &.btn-primary {
                            background-color: $blue-hover;
                            border: 1px solid $blue-hover;
                        }
                    }
                }
            }

            &.active,
            .focus {

                &.focus,
                &:focus,
                &:hover {
                    .open {
                        &>.dropdown-toggle {
                            &.btn-primary {

                                &.focus,
                                &:focus,
                                &:hover {
                                    background-color: $blue-hover;
                                    border: 1px solid $blue-hover;
                                }
                            }
                        }
                    }
                }
            }

            &:active {

                &:focus,
                &:hover {
                    background-color: $blue-hover;
                    border: 1px solid $blue-hover;
                }
            }

            .active,
            &:active {
                &:not(:disabled):not(.disabled) {
                    background-color: $blue-hover;
                    border-color: $blue-hover;
                    color: $white;
                }

                &:focus {
                    &:not(:disabled):not(.disabled) {
                        box-shadow: unset;
                    }
                }
            }

            .show {
                &>.btn-primary {
                    &.dropdown-toggle {
                        background-color: $blue-hover;
                        border-color: $blue-hover;
                        color: $white;

                        &:focus {
                            box-shadow: unset;
                        }
                    }
                }
            }

            &.disabled,
            &:disabled {
                background-color: $blue;
                border-color: $blue;
                color: $white;
            }
        }

        .roles-menu ul li.active a {
            border-color: $blue;
            color: $blue;
        }

        .settings-icon {
            span {
                background-color: $blue;
            }
        }

        .profile-view .profile-basic .profile-info-left .staff-msg .btn-custom {
            background: $blue-gradient;
        }

        .profile-view .profile-basic .profile-info-left .staff-msg .btn-custom:hover {
            border-color: $blue;
        }

        a {
            color: $blue;

            &:hover {
                color: $blue-hover;
            }
        }

        .nav-tabs.nav-tabs-bottom li a.active {
            border-bottom-color: $blue;
        }

        .custom-modal .modal-content .modal-header .btn-close {
            background-color: $blue;
            border: 1px solid $blue;
        }

        .custom-modal .modal-content .modal-header .btn-close:hover {
            background-color: transparent;
            color: $blue;
        }

        .breadcrumb .breadcrumb-item.active {
            color: $blue;
        }

        ::selection {
            background: $blue;
            color: $white;
            text-shadow: none;
        }
    }

    .defaultColorTopbar.blueScheme {
        .header {
            background: $blue;
            background: $blue-gradient;
            border-color: transparent;

            .header-left .logo {
                display: block;
                line-height: 60px;
            }

            .user-menu.nav>li>a {
                color: $white;
            }

            #toggle_btn .bar-icon span {
                background-color: $white;
            }

            .page-title-box h3 {
                color: $white;
            }

            .logo2 {
                display: none !important;
            }
        }

        .header .has-arrow .dropdown-toggle:after {
            border-bottom: 2px solid #fff;
            border-right: 2px solid #fff;
        }

        .header .top-nav-search form .form-control {
            color: #fff;

            &::placeholder {
                color: #fff;
            }
        }

        .header .top-nav-search form .btn {
            color: #fff;
        }

    }

    .blueScheme {
        .add-btn {
            background-color: $blue;
            border-color: $blue;
        }
    }

    .defaultColorTopbar.maroonScheme {
        .header {
            background: $maroon;
            background: $maroon-gradient;
            border-color: transparent;

            .header-left .logo {
                display: block;
                line-height: 60px;
            }

            .user-menu.nav>li>a {
                color: $white
            }

            #toggle_btn .bar-icon span {
                background-color: $white;
            }

            .page-title-box h3 {
                color: $white;
            }

            .logo2 {
                display: none;
            }
        }
    }

    .maroonScheme {
        .header {
            background: $maroon;
            background: $maroon-gradient;
            border-color: transparent;

            .header-left .logo {
                display: block;
                line-height: 60px;
            }

            .user-menu.nav>li>a {
                color: $white
            }

            #toggle_btn .bar-icon span {
                background-color: $white;
            }

            .page-title-box h3 {
                color: $white;
            }

            .logo2 {
                display: none;
            }
        }

        .page-item.active {
            .page-link {
                background-color: $maroon ;
                border-color: $maroon ;
            }
        }

        .nav-tabs.nav-tabs-solid {
            li a.active {
                background-color: $maroon;
                border-color: $maroon;
            }
        }

        .sidebar-twocol.sidebar {
            .sidebar-left {
                background: $secondary-800;
            }

            .sidebar-right ul li a.active {
                color: $maroon;
            }
        }

        .bg-primary,
        .badge-primary {
            background-color: $maroon !important;
        }

        .dash-widget {
            .card-body {
                .dash-widget-icon {
                    background-color: rgba(244, 59, 72, 0.2);
                    color: $maroon;
                }
            }
        }

        #layout-position .radio input:checked+label {
            background: $maroon;
            color: $white;
        }

        .sidebar {
            .sidebar-menu {
                ul ul a.active {
                    color: $maroon !important;
                    text-decoration: underline;
                }
            }

            ul li.submenu .noti-dot:before {
                border: 5px solid $maroon;
            }

        }

        .roles-menu ul li.active a {
            border-color: $maroon;
            color: $maroon;
        }

        .settings-icon {
            span {
                background-color: $maroon;
            }
        }

        .btn-primary {
            background-color: $maroon;
            border: 1px solid $maroon;

            &:focus,
            &:hover,
            &:active,
            &.active {
                color: $white;
                background-color: $maroon-hover;
                border-color: $maroon-hover;

                .open {
                    &>.dropdown-toggle {
                        &.btn-primary {
                            background-color: $maroon-hover;
                            border: 1px solid $maroon-hover;
                        }
                    }
                }
            }

            &.active,
            .focus {

                &.focus,
                &:focus,
                &:hover {
                    .open {
                        &>.dropdown-toggle {
                            &.btn-primary {

                                &.focus,
                                &:focus,
                                &:hover {
                                    background-color: $maroon-hover;
                                    border: 1px solid $maroon-hover;
                                }
                            }
                        }
                    }
                }
            }

            &:active {

                &:focus,
                &:hover {
                    background-color: $maroon-hover;
                    border: 1px solid $maroon-hover;
                }
            }

            .active,
            &:active {
                &:not(:disabled):not(.disabled) {
                    background-color: $maroon-hover;
                    border-color: $maroon-hover;
                    color: $white;
                }

                &:focus {
                    &:not(:disabled):not(.disabled) {
                        box-shadow: unset;
                    }
                }
            }

            .show {
                &>.btn-primary {
                    &.dropdown-toggle {
                        background-color: $maroon-hover;
                        border-color: $maroon-hover;
                        color: $white;

                        &:focus {
                            box-shadow: unset;
                        }
                    }
                }
            }

            &.disabled,
            &:disabled {
                background-color: $maroon;
                border-color: $maroon;
                color: $white;
            }
        }

        .roles-menu ul li.active a {
            border-color: $maroon;
            color: $maroon;
        }

        .settings-icon {
            span {
                background-color: $maroon;
            }
        }

        .profile-view .profile-basic .profile-info-left .staff-msg .btn-custom {
            background: $maroon-gradient;
        }

        .profile-view .profile-basic .profile-info-left .staff-msg .btn-custom:hover {
            border-color: $maroon;
        }

        a {
            color: $maroon;

            &:hover {
                color: $maroon-hover;
            }
        }

        .nav-tabs.nav-tabs-bottom li a.active {
            border-bottom-color: $maroon;
        }

        .custom-modal .modal-content .modal-header .btn-close {
            background-color: $maroon;
            border: 1px solid $maroon;
        }

        .custom-modal .modal-content .modal-header .btn-close:hover {
            background-color: transparent;
            color: $maroon;
        }

        .breadcrumb .breadcrumb-item.active {
            color: $maroon;
        }

        ::selection {
            background: $maroon;
            color: $white;
            text-shadow: none;
        }
    }

    .defaultColorTopbar.maroonScheme {
        .header {
            background: $maroon;
            background: $maroon-gradient;
            border-color: transparent;

            .header-left .logo {
                display: block;
                line-height: 60px;
            }

            .user-menu.nav>li>a {
                color: $white
            }

            #toggle_btn .bar-icon span {
                background-color: $white;
            }

            .page-title-box h3 {
                color: $white;
            }

            .logo2 {
                display: none;
            }
        }
    }

    .maroonScheme {
        .add-btn {
            background-color: $maroon;
        }
    }

    .purpleScheme {
        .header {
            background: $violet;
            background: $violet-gradient;
            border-color: transparent;

            .header-left .logo {
                display: block;
                line-height: 60px;
            }

            .user-menu.nav>li>a {
                color: $white
            }

            #toggle_btn .bar-icon span {
                background-color: $white;
            }

            .page-title-box h3 {
                color: $white;
            }

            .logo2 {
                display: none;
            }
        }

        .page-item.active {
            .page-link {
                background-color: $violet ;
                border-color: $violet ;
            }
        }

        .nav-tabs.nav-tabs-solid {
            li a.active {
                background-color: $violet;
                border-color: $violet;
            }
        }

        .sidebar-twocol.sidebar {
            .sidebar-left {
                background: $secondary-800;
            }

            .sidebar-right ul li a.active {
                color: $violet;
            }
        }

        .bg-primary,
        .badge-primary {
            background-color: $violet !important;
        }

        #layout-position .radio input:checked+label {
            background: $violet;
            color: $white;
        }

        .dash-widget {
            .card-body {
                .dash-widget-icon {
                    background-color: rgba(102, 126, 234, 0.2);
                    color: $violet;
                }
            }
        }

        .sidebar {
            .sidebar-menu {
                ul ul a.active {
                    color: $violet !important;
                    text-decoration: underline;
                }
            }

            ul li.submenu .noti-dot:before {
                border: 5px solid $violet;
            }

        }

        .bg-primary,
        .badge-primary {
            background-color: $violet !important;
        }

        .roles-menu ul li.active a {
            border-color: $violet;
            color: $violet;
        }

        .settings-icon {
            span {
                background-color: $violet;
            }
        }

        .btn-primary {
            background-color: $violet;
            border: 1px solid $violet;

            &:focus,
            &:hover,
            &:active,
            &.active {
                color: $white;
                background-color: $violet-hover;
                border-color: $violet-hover;

                .open {
                    &>.dropdown-toggle {
                        &.btn-primary {
                            background-color: $violet-hover;
                            border: 1px solid $violet-hover;
                        }
                    }
                }
            }

            &.active,
            .focus {

                &.focus,
                &:focus,
                &:hover {
                    .open {
                        &>.dropdown-toggle {
                            &.btn-primary {

                                &.focus,
                                &:focus,
                                &:hover {
                                    background-color: $violet-hover;
                                    border: 1px solid $violet-hover;
                                }
                            }
                        }
                    }
                }
            }

            &:active {

                &:focus,
                &:hover {
                    background-color: $violet-hover;
                    border: 1px solid $violet-hover;
                }
            }

            .active,
            &:active {
                &:not(:disabled):not(.disabled) {
                    background-color: $violet-hover;
                    border-color: $violet-hover;
                    color: $white;
                }

                &:focus {
                    &:not(:disabled):not(.disabled) {
                        box-shadow: unset;
                    }
                }
            }

            .show {
                &>.btn-primary {
                    &.dropdown-toggle {
                        background-color: $violet-hover;
                        border-color: $violet-hover;
                        color: $white;

                        &:focus {
                            box-shadow: unset;
                        }
                    }
                }
            }

            &.disabled,
            &:disabled {
                background-color: $violet;
                border-color: $violet;
                color: $white;
            }
        }

        .roles-menu ul li.active a {
            border-color: $violet;
            color: $violet;
        }

        .settings-icon {
            span {
                background-color: $violet;
            }
        }

        .profile-view .profile-basic .profile-info-left .staff-msg .btn-custom {
            background: $violet-gradient;
        }

        .profile-view .profile-basic .profile-info-left .staff-msg .btn-custom:hover {
            border-color: $violet;
        }

        a {
            color: $violet;

            &:hover {
                color: $violet-hover;
            }
        }

        .nav-tabs.nav-tabs-bottom li a.active {
            border-bottom-color: $violet;
        }

        .custom-modal .modal-content .modal-header .btn-close {
            background-color: $violet;
            border: 1px solid $violet;
        }

        .custom-modal .modal-content .modal-header .btn-close:hover {
            background-color: transparent;
            color: $violet;
        }

        .breadcrumb .breadcrumb-item.active {
            color: $violet;
        }

        ::selection {
            background: $violet;
            color: $white;
            text-shadow: none;
        }
    }

    .defaultColorTopbar.purpleScheme {
        .header {
            background: $violet;
            background: $violet-gradient;
            border-color: transparent;

            .header-left .logo {
                display: block;
                line-height: 60px;
            }

            .user-menu.nav>li>a {
                color: $white
            }

            #toggle_btn .bar-icon span {
                background-color: $white;
            }

            .page-title-box h3 {
                color: $white;
            }

            .logo2 {
                display: none;
            }
        }

        .header .has-arrow .dropdown-toggle:after {
            border-bottom: 2px solid #fff;
            border-right: 2px solid #fff;
        }

        .header .top-nav-search form .form-control {
            color: #fff;

            &::placeholder {
                color: #fff;
            }
        }

        .header .top-nav-search form .btn {
            color: #fff;
        }

        .header .header-left .logo2 {
            display: none;
        }
    }

    .boxedWidth .sidebar {
        ul {
            .menu-title {
                display: none !important;
            }

            li {
                span {
                    display: none !important;
                }
            }

            ul {
                li {
                    display: none !important;
                }
            }
        }
    }

    .boxedWidth .page-wrapper {
        margin: 0 0 0 230px;
    }

    .main-wrapper.boxedWidth {
        position: relative;

        #toggle_btn {
            display: none;
        }
    }

    .boxedWidth.sidebar {
        position: absolute;
        width: 230px;

        .noti-dot {
            display: none !important;
        }

        ul {
            .menu-title {
                display: block !important;
            }

            li {
                span {
                    display: block !important;
                }
            }

            ul {
                li {
                    display: block !important;
                }
            }
        }
    }

    .sm-hover,
    .smallHoverSidebarSize {
        #toggle_btn {
            display: none;
        }

        .sidebar {

            ul {
                .menu-title {
                    display: none !important;
                }

                li {
                    span {
                        display: none !important;
                    }
                }

                ul {
                    li {
                        display: none !important;
                        width: 100%;
                    }
                }
            }
        }

        .two-col-bar {
            &:hover {
                .sidebar-right {
                    display: block;
                }
            }

            .sidebar-left {
                display: block;
            }

            .sidebar-right {
                display: none;
            }
        }

        .page-wrapper {
            margin: 0 0 0 60px;
        }

        .sidebar {
            width: 60px;

            .noti-dot {
                display: none !important;
            }

            .sidebar-menu {
                ul li span {
                    display: none !important;
                }
            }

            &:hover {
                width: 230px;

                ul {
                    .menu-title {
                        display: inline-block !important;
                    }

                    li {
                        span {
                            display: inline-block !important;
                        }
                    }

                    ul {
                        li {
                            display: inline-block !important;
                        }
                    }
                }
            }
        }
    }

    .main-wrapper.scrollableLayout {
        position: relative;

        .sidebar {
            position: absolute;
        }
    }

    .scrollableLayout .slimScrollDiv,
    .scrollableLayout.slimscroll {
        overflow: auto !important;
        height: auto !important;
    }

    .darkSidebarColor.scrollableLayout .slimscroll {
        background-color: #34444c;
    }

    .gradientSidebarColor {
        .sidebar {
            background: linear-gradient(92.08deg, #FF0000 0%, #764BA2 100%);
        }

        .sidebar-twocol.sidebar {
            .sidebar-left {
                background: linear-gradient(92.08deg, #FF0000 0%, #764BA2 100%);
            }

            .sidebar-right ul li a.active {
                color: #405189;
            }
        }
    }

    .gradientSidebarColor.scrollableLayout .slimScrollDiv,
    .gradientSidebarColor.scrollableLayout .slimscroll {
        background: linear-gradient(92.08deg, #FF0000 0%, #764BA2 100%);

    }

    .main-wrapper.scrollableLayout.detachedSidebarView .sidebar {
        position: absolute;
        left: 30px;

        .slimscroll {
            background: #34444c;
            border-radius: 10px;
        }
    }

    .gradientSidebarColor.scrollableLayout.detachedSidebarView .slimscroll {
        background: linear-gradient(92.08deg, #FF0000 0%, #764BA2 100%) !important;
        border-radius: 10px !important;
    }

    .slimScrollDiv {
        background: transparent !important;
    }

    .compactSidebarSize {
        .page-wrapper {
            margin: 0 0 0 200px;
        }

        .sidebar {
            width: 200px;

            .menu-title {
                justify-content: center;
            }

            .sidebar-menu ul li a {
                flex-direction: column;
                text-align: center;
                padding: 10px;
            }
        }

        #toggle_btn {
            display: none;
        }
    }

    .boxedWidth.compactSidebarSize {
        .sidebar {
            &:hover {
                width: 200px;
            }

            ul {
                .menu-title {
                    display: block !important;
                    text-align: center;
                }

                li span {
                    display: block !important;
                }

                ul li {
                    display: block !important;
                }
            }
        }
    }

    .main-wrapper.detachedSidebarView {
        max-width: 95%;
        margin: 0 auto;
        padding-left: 1.5rem;
        position: relative;

        .sidebar {
            position: fixed;
            top: 80px;
            border-radius: 10px;
            left: 50px;

            &.sidebar-twocol {
                top: 30px;
                left: 50px;
                position: absolute;
            }

            @include respond-below(custom991) {
                left: 0;
            }
        }
    }

    .detachedSidebarView .sidebar .sidebar-menu,
    .detachedSidebarView .two-col-bar .sidebar-menu {
        padding: 0;
    }

    .detachedSidebarView #toggle_btn {
        display: none;
    }

    .darkSidebarColor {
        .sidebar-twocol.sidebar {
            .sidebar-left {
                background-color: $secondary-800;
            }

            .sidebar-left .nav-link {
                color: #333;
                background: transparent;

                &.active {
                    color: #fff;
                    background: #333;
                }
            }
        }

        .sidebar {
            box-shadow: 0 1px 1px 0 rgb(0 0 0 / 20%);
            background-color: #34444c;

            .sidebar-menu {
                .greedy ul li a:hover {
                    color: #fff;
                }

                ul li a:hover {
                    color: #fff;
                }
            }
        }

        .two-col-bar {
            .sidebar .sidebar-left {
                .nav-link {
                    @include margin-padding(null, 5px);
                    margin-bottom: 15px;
                    color: #fff;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    justify-content: center;
                    -webkit-justify-content: center;
                    -ms-flex-pack: center;

                    &.active {
                        background: #fff;
                        color: #34444c;
                    }
                }
            }
        }
    }

    .boxedWidth .sidebar {
        ul {
            .menu-title {
                display: none !important;
            }

            li {
                span {
                    display: none !important;
                }
            }

            ul {
                li {
                    display: none !important;
                }
            }
        }
    }

    .boxedWidth .page-wrapper {
        margin: 0 0 0 230px;
    }

    .boxedWidth .main-wrapper {
        position: relative;

        #toggle_btn {
            display: none;
        }
    }

    .boxedWidth .sidebar {
        position: absolute;
        width: 230px;

        .noti-dot {
            display: none !important;
        }

        ul {
            .menu-title {
                display: block !important;
            }

            li {
                span {
                    display: block !important;
                }
            }

            ul {
                li {
                    display: block !important;
                }
            }
        }
    }


    .defaultColorTopbar {
        .header {
            background: $white;
            border-color: transparent;

            .page-title-box {
                h3 {
                    color: $secondary-900;
                }
            }

            .top-nav-search form {
                .form-control {
                    border: 1px solid #d5d5d5;
                    color: #333;

                    &::-webkit-input-placeholder {
                        /* Edge */
                        color: #333;
                    }

                    &:-ms-input-placeholder {
                        /* Internet Explorer 10-11 */
                        color: #333;
                    }

                    &::placeholder {
                        color: #333;
                    }
                }

                .btn {
                    color: #333;
                }
            }

            .header-left {
                .logo2 {
                    display: inline-block;
                    line-height: 60px;
                }

                .logo {
                    display: none;
                    line-height: 60px;
                }
            }

            #toggle_btn {
                .bar-icon span {
                    background-color: $secondary-900;
                }
            }

            .user-menu.nav>li>a {
                color: $secondary-900;
            }

            .has-arrow .dropdown-toggle:after {
                border-bottom: 2px solid #333;
                border-right: 2px solid #333;
                border-right: 2px solid #000;
            }
        }
    }

    .lightSidebarColor {
        .sidebar {
            .sidebar-menu {
                ul {
                    ul {
                        li {
                            a:hover {
                                color: $white;
                            }

                            &.submenu {
                                ul {
                                    li {
                                        a {
                                            color: $secondary-400;

                                            span {
                                                color: $secondary-400;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .darkScheme .pipeline-stages li .edit-delete-stage a {
        color: #BCBEBF;
    }

    .orangeScheme .top-nav-search .form-control::-moz-placeholder {
        color: $light-600 !important;
    }

    .lightScheme .top-nav-search .form-control::-moz-placeholder {
        color: $secondary-900 !important;

        .lightScheme {
            .header {
                .user-menu.nav {
                    >li {
                        &.dropdown {
                            .nav-link {
                                background: $light-500;
                            }
                        }

                        &.main-drop {
                            a {
                                &.nav-link {
                                    background: transparent;
                                    width: auto;
                                    display: inline;
                                    margin: 0;
                                }
                            }
                        }
                    }

                }
            }
        }

        .darkScheme {

            .pipeline-stages {
                li {
                    .edit-delete-stage {
                        a {
                            color: #BCBEBF;
                        }
                    }
                }
            }

            .access-info-tab .custom_radio .checkmark::after {
                background: transparent;
            }

            .access-info-tab .custom_radio.active .checkmark::after {
                background: $white;
            }

            .person-selected li .profile-pic {
                color: #BCBEBF;
            }

            #last-chart {
                text {
                    fill: #BCBEBF;
                }
            }

            #won-chart {
                text {
                    fill: #BCBEBF;
                }
            }

            #year-chart {
                text {
                    fill: #BCBEBF;
                }
            }

            #leadchart {
                text {
                    fill: #BCBEBF;
                }
            }

            .apexcharts-legend-text {
                color: #BCBEBF !important;
            }

            .analytics-card {
                text {
                    fill: #BCBEBF;
                }
            }
        }

        body .darkScheme.defaultColorTopbar {
            .header {
                background: $body-dark-bg;
                border-color: $dark-border;

                .header-left .logo {
                    display: block;
                    line-height: 60px;
                }

                .dropdown-toggle {
                    &::after {
                        border-color: $white !important;
                    }
                }

                .top-nav-search form {
                    .form-control {
                        border: 1px solid #bbc4cc;
                        color: #bbc4cc;

                        &::-webkit-input-placeholder {
                            /* Edge */
                            color: #bbc4cc;
                        }

                        &:-ms-input-placeholder {
                            /* Internet Explorer 10-11 */
                            color: #bbc4cc;
                        }

                        &::placeholder {
                            color: #bbc4cc;
                        }
                    }

                    .btn {
                        color: #bbc4cc;
                    }
                }

                .user-menu.nav>li>a {
                    color: $secondary-300;
                }

                #toggle_btn .bar-icon span {
                    background-color: $secondary-300;
                }

                .page-title-box h3 {
                    color: $secondary-300;
                }

                .logo2 {
                    display: none;
                }
            }
        }
    }
}