.rtl{
    .apexcharts-legend.apexcharts-align-center.position-right {
        display: none;
    }
    .apexcharts-toolbar {
        display: none !important;
    }
    #sales_charts,
    .chart-set{
        overflow: hidden;
    }
    .apexcharts-canvas {
        width: 100% !important;
    }    
}