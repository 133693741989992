.ltr {
  .popover-list {
    button {
      margin: 0 0.375rem 0.375rem 0;
    }

    a {
      margin: 0 0.375rem 0.375rem 0;
    }
  }

  @mixin pop-header-color($color) {
    .popover-header {
      background-color: $color;
      color: $white;
    }

    &.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::after,
    &.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
      border-bottom-color: $color;
    }
  }

  .header-primary {
    @include pop-header-color($primary);
  }

  .header-secondary {
    @include pop-header-color($secondary);
  }

  .header-warning {
    @include pop-header-color($warning);
  }

  .header-info {
    @include pop-header-color($info);
  }

  .header-success {
    @include pop-header-color($success);
  }

  .header-danger {
    @include pop-header-color($danger);
  }

  @mixin pop-bgcolor($color) {
    &.popover {
      border: 1px solid $color;

      .popover-header {
        background-color: $color;
        border-block-end: 1px solid rgba(255, 255, 255, 0.1);
        color: $white;
        border-radius: 0.3rem 0.3rem 0 0;
      }

      .popover-body {
        background-color: $color;
        color: $white;
        border-radius: 0 0 0.3rem 0.3rem;
      }
    }

    &.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::after,
    &.bs-popover-top>.popover-arrow::after,
    &.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::before,
    &.bs-popover-top>.popover-arrow::before {
      border-top-color: $color;
    }

    &.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::after,
    &.bs-popover-end>.popover-arrow::after,
    &.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::before,
    &.bs-popover-end>.popover-arrow::before {
      border-right-color: $color;
    }

    &.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::after,
    &.bs-popover-start>.popover-arrow::after,
    &.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::before,
    &.bs-popover-start>.popover-arrow::before {
      border-left-color: $color;
    }

    &.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::after,
    &.bs-popover-bottom>.popover-arrow::after,
    &.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::before,
    &.bs-popover-bottom>.popover-arrow::before,
    &.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before,
    &.bs-popover-bottom .popover-header::before {
      border-bottom-color: $color;
    }
  }

  .popover-primary {
    @include pop-bgcolor($primary);
  }

  .popover-secondary {
    @include pop-bgcolor($secondary);
  }

  .popover-warning {
    @include pop-bgcolor($warning);
  }

  .popover-info {
    @include pop-bgcolor($info);
  }

  .popover-success {
    @include pop-bgcolor($success);
  }

  .popover-danger {
    @include pop-bgcolor($danger);
  }
}