.rtl{
    .ticket-detail-head {
        .ticket-head-card {
            display: flex;
            align-items: center;
            box-shadow: 0px 4px 24px 0px #E1E1E140;
            border: 1px solid $secondary-200;
            background: $white;
            padding: 15px;
            border-radius: 5px;
            margin-bottom: 24px;
            .ticket-detail-icon {
                width: 44px;
                height: 44px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $primary-800;
                flex-shrink: 0;
                margin-left: 10px;
                i {
                    font-size: $font-size-24;
                    color: $white;
                }
                &.bg-danger-lights {
                    background: $pink-800;
                }
                &.bg-warning-lights {
                    background: $warning-800;
                }
                &.bg-purple-lights {
                    background: $purple-800;
                }
            }
            .detail-info {
                h6 {
                    font-weight: $font-weight-normal;
                    margin-bottom: 5px;
                    font-size: $font-size-14;
                }
                &.info-two {
                    span {
                        font-size: $font-size-14;
                        color: $secondary-800;
                    }
                }
            }
        }
    }
    .ticket-purpose {
        border: 1px solid $secondary-200;
        background: $white;
        padding: 24px;
        border-radius: 5px;
        margin-bottom: 24px;
        h4 {
            font-size: $font-size-18;
            margin-bottom: 15px;
        }
        p {
            font-size: $font-size-14;
            margin-bottom: 10px; 
            color: $secondary-800;
        }
        ul {
            li {
                font-size: $font-size-14;
                margin-bottom: 5px;
                position: relative;
                color: $secondary-800;
                padding-right: 20px;
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    content: "";
                    width: 6px;
                    height: 6px;
                    border-radius: 20px;
                    background: $secondary-800;
                    position: absolute;
                    right: 5px;
                    top: 6px;
                }
            }
        }
    }
    .attached-files-info {
        .attached-files {
            box-shadow: 0px 4px 24px 0px #BCBCBC40;
            border-radius: 5px;
            padding: 24px;
            background: $white;
            ul {
                li {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    @include respond-below(custom575) {
                        flex-direction: column;
                        align-items: self-start;
                    }
                    .file-icon {
                        width: 40px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: $primary-100;
                        margin-left: 10px;
                        flex-shrink: 0;
                        border-radius: 50%;
                        i {
                            color: $primary;
                            font-size: $font-size-20;
                        }
                    }
                    p {
                        font-size: $font-size-14;
                        margin-bottom: 0;
                        color: $secondary-900;
                    }
                    .file-download {
                        @include respond-below(custom575) {
                            margin-top: 15px;
                        }
                        a {
                            color: $secondary-900;
                            display: flex;
                            align-items: center;
                            i {
                                margin-left: 5px;
                            }
                        }
                    }
                    & + li {
                        margin-top: 15px;
                    }
                }
            }
            &.media-attached-files {
                @include respond-below(custom1399) {
                    margin-top: 24px;
                }
               
                .file-icon {
                    background: $danger-100;
                    i {
                        color: $danger;
                    }
                }
            }
        }
    }
    .ticket-chat {
        border: 1px solid $secondary-200;
        background: $white;
        padding: 24px;
        border-radius: 5px;
        @include respond-below(custom991) {
            margin-top: 24px;
        }
        .ticket-chat-head {
            h4 {
                margin-bottom: 15px;
            }
            .chat-post-box {
                border: 1px solid $secondary-200;
                background: $white;
                padding: 15px;
                border-radius: 5px;
                margin-bottom: 15px;
                .files-attached {
                    margin-top: 10px;
                    .post-files {
                        display: flex;
                        align-items: center;
                        a {
                            width: 36px;
                            height: 36px;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: $light-500;
                            i {
                                font-size: 18px;
                                color: $secondary-900;
                            }
                            & + a {
                                margin-right: 10px;
                            }
                        }
                    }
                    
                    button {
                        background: $primary;
                        border: 1px solid $primary;
                        color: $white;
                        padding: 8px 12px;
                        border-radius: 5px;
                        line-height: normal;
                    }
                }
            }
        }
        .ticket-chat-body {
            .created-tickets-info {
                li {
                    .ticket-created-user {
                        display: flex;
                        align-items: center;
                        .avatar {
                            flex-shrink: 0;
                            margin-left: 10px;
                        }
                        .user-name {
                            h5 {
                                font-weight: $font-weight-normal;
                                font-size: $font-size-15;
                                span {
                                    font-weight: $font-weight-medium;
                                }
                            }
                        }
                    }
                    & + li {
                        margin-top: 15px;
                        padding-top: 15px;
                        border-top: 1px solid $secondary-200;
                    }
                    .ticket-created-info {
                        h6 {
                            margin-bottom: 5px;
                        }
                        p {
                            font-size: $font-size-14;
                            margin-bottom: 10px;
                        }
                        .comment-text {
                            color: $pink;
                        }
                    }
                    .details-text {
                        margin-top: 10px;
                        font-size: $font-size-14;
                    }
                }
            }
        }
        .ticket-chat-footer {
            margin-top: 15px;
            button {
                background: $primary;
                border: 1px solid $primary;
                color: $white;
                line-height: normal;
                margin-right: 15px;
                height: 44px;
                width: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: $font-size-24;
                border-radius: 5px;
            }
        }
    }
    
    [data-layout-mode=dark] body {
        h1,h2,h3,h4,h5,h6,p {
            color: $secondary-300;
        }
        .ticket-detail-head {
            .ticket-head-card {
                background: $body-dark-bg;
                border-color: $body-dark-bg;
            }
        } 
        .ticket-purpose{
            background: $body-dark-bg;
            border-color: $black;
            ul {
                li {
                    color: $secondary-300;
                }
            }
        }
        .attached-files-info .attached-files {
            background: $body-dark-bg;
            border-color: $black;
            ul {
                li {
                    p {
                        color: $secondary-300;
                    }
                    .file-download {
                        a {
                            color: $secondary-300;
                        }
                    }
                }
            }
        }
        .ticket-chat {
            background: $body-dark-bg;
            border-color: $black;
            .ticket-chat-head {
                .chat-post-box {
                    background: $body-dark-bg;
                    border-color: $black;
                }
            }
        }
    }
    textarea.form-control {
        resize: none;
    }
}