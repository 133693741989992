.rtl {

    .disabled>.page-link,
    .page-link.disabled {
        opacity: 0.7;
        color: $primary;
    }

    .pagination-style-1 .pagination {
        .page-item {
            margin: 0 0.25rem;

            .page-link {
                border: 0;
                border-radius: 4px;
                font-size: 0.8125rem;

                i {
                    font-weight: $font-weight-semibold;
                }
            }

            &.active {
                .page-link {
                    border-radius: 4px;
                    background-color: $primary;
                    color: $white;
                }

                &:hover {
                    .page-link {
                        border-radius: 4px;
                        background-color: $primary;
                        color: $white;
                    }
                }
            }

            &:hover {
                .page-link {
                    background-color: $light-200;
                    color: $primary;
                }
            }
        }
    }

    .pagination-style-2 .pagination {
        border-radius: 4px;

        .page-item {
            margin: 0 0.25rem;

            .page-link {
                border: 0 !important;
                font-size: 0.8125rem;
            }

            &.active {
                .page-link {
                    background-color: $white;
                    color: $primary;
                    position: relative;
                    font-weight: bold;

                    &:before {
                        position: absolute;
                        content: "";
                        inset-block-end: 0;
                        inset-inline-start: 0;
                        width: 100%;
                        height: 0.1rem;
                        background-color: $primary;
                    }
                }
            }

            &:hover {
                .page-link {
                    background-color: transparent;
                }
            }
        }
    }

    .pagination-style-3 .pagination {
        border-radius: 50px;
        padding: 0.25rem;
        align-items: center;

        .page-item {
            margin: 0 0.25rem;

            .page-link {
                border: 0;
                border-radius: 50px;
                font-size: 0.8125rem;

                i {
                    font-weight: $font-weight-semibold;
                }
            }

            &.active {
                .page-link {
                    background-color: $primary;
                    color: $white;
                }

                &:hover {
                    .page-link {
                        background-color: $primary;
                    }
                }
            }

            &:hover {
                .page-link {
                    background-color: $light-200;
                }
            }
        }
    }

    .pagination-style-4 .pagination {
        .page-item {
            .page-link {
                border: 0 !important;
                font-size: 0.8125rem;
                border-radius: 4px;

                i {
                    font-weight: $font-weight-semibold;
                }
            }

            &.active {
                .page-link {
                    border: 0;
                    border-radius: 4px;
                    background-color: $primary;
                    color: $white;
                }

                &:hover {
                    .page-link {
                        background-color: $primary;
                    }
                }
            }

            &:hover {
                .page-link {
                    background-color: transparent;
                }
            }
        }
    }
}