.ltr{
	.avatar {
		background-color: transparent;
		color: $white;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		font-weight: $font-weight-medium;
		height: 34px;
		line-height: 34px;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		vertical-align: middle;
		width: 34px;
		position: relative;
		white-space: nowrap;
		@include margin-padding( 0 10px 0 0, null);
		@include rounded(50%);
		&.avatar-xs {
			width: 24px;
			height: 24px;
			line-height: 24px;
			display: inline-flex;
		}
		& > img {
			width: 100%;
			height: 100%;
			-o-object-fit: cover;
			object-fit: cover;
			@include rounded(50%);
		}
		.avatar-title {
			width: 100%;
			height: 100%;
			background-color: $info-900;
			color: $white;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			align-items: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			justify-content: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
		}
		&.avatar-online {
			&::before {
				width: 25%;
				  height: 25%;
				 @include rounded(50%);
				  content: '';
				  border: 2px solid $white;
				  @include position(absolute, null, 0, 0, null);
				background-color: $success;
			}
		}
		&.avatar-offline {
			&::before {
				width: 25%;
				  height: 25%;
				 @include rounded(50%);
				  content: '';
				  border: 2px solid $white;
				  @include position(absolute, null, 0, 0, null);
				background-color: $danger;
			}
		}
		&.avatar-away {
			&::before {
				width: 25%;
				  height: 25%;
				 @include rounded(50%);
				  content: '';
				  border: 2px solid $white;
				  @include position(absolute, null, 0, 0, null);
				background-color: $warning;
			}
		}
		.border {
			border-width: 3px !important;
		}
		.rounded {
			@include rounded(6px !important);
		}
		.avatar-title {
			font-size: $font-size-18;
		}
		.avatar-xs {
			width: 1.65rem;
			height: 1.65rem;
			.border {
				border-width: 2px !important;
			}
			.rounded {
				@include rounded(4px !important);
			}
			.avatar-title {
				font-size: $font-size-12;
			}
			&.avatar-away, &.avatar-offline, &.avatar-online {
				&::before {
					border-width: 1px;
				}
			}
		}
	}
	.avatar-sm {
		width: 2.5rem;
		height: 2.5rem;
		.border {
			border-width: 3px !important;
		}
		.rounded {
			@include rounded(4px !important);
		}
		.avatar-title {
			font-size: $font-size-15;
		}
		&.avatar-away, &.avatar-offline, &.avatar-online {
			&::before {
				border-width: 2px;
			}
		}
	}
	.avatar-lg {
		width: 3.75rem;
		height: 3.75rem;
		.border {
			border-width: 3px !important;
		}
		.rounded {
			@include rounded(8px !important);
		}
		.avatar-title {
			font-size: $font-size-24;
		}
		&.avatar-away, &.avatar-offline, &.avatar-online {
			&::before {
				border-width: 3px;
			}
		}
	}
	.avatar-xl {
		width: 5rem;
		height: 5rem;
		.border {
			border-width: 4px !important;
		}
		.rounded {
			@include rounded(8px !important);
		}
		.avatar-title {
			font-size: $font-size-28;
		}
		&.avatar-away, &.avatar-offline, &.avatar-online {
			&::before {
				border-width: 4px;
			}
		}
	}
	.avatar-xxl {
		width: 5.125rem;
		height: 5.125rem;
		@include respond-above(custom768) {
			width: 8rem;
			height: 8rem;
		}
		.border {
			border-width: 6px !important;
			@include respond-above(custom768) {
				border-width: 4px !important;
			}
		}
		.rounded {
			@include rounded(8px !important);
			@include respond-above(md) {
				@include rounded(12px !important);
			}
		}
		.avatar-title {
			font-size: $font-size-30;
			@include respond-above(md) {
				font-size: $font-size-42;
			}
		}
		&.avatar-away, &.avatar-offline, &.avatar-online {
			&::before {
				border-width: 4px;
				@include respond-above(md) {
					border-width: 4px;
				}
			}
		}
	}
	.avatar-group {
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
		.avatar {
			& + .avatar {
				margin-left: -.75rem;
			}
			&:hover {
				z-index: 1;
			}
		}
		.avatar-xs {
			& + .avatar-xs {
				margin-left: -.40625rem;
			}
		}
		.avatar-sm {
			& + .avatar-sm {
				margin-left: -.625rem;
			}
		}
		.avatar-lg {
			& + .avatar-lg {
				margin-left: -1rem;
			}
		}
		.avatar-xl {
			& + .avatar-xl {
				margin-left: -1.28125rem;
			}
		}
	}
	.avatar-xs {
		width: 1.65rem;
		height: 1.65rem;
	}
	.leave-employee-table {
		.avatar {
			line-height: normal;
		}
		.avatar-xs {
			line-height: normal;
		}
	}
	.avatar-group {
		.avatar {
			margin: 0;
		}
	}
	.avatar.avatar-xl {
		width: 4rem;
		height: 4rem;
		line-height: 4rem;
		font-size: 1.25rem;
	}
	.avatar.avatar-radius-0 img {
		border-radius: 0;
	}
	.avatar.border-thumb img {
		border-radius: 5px;
	}
	.border-thumb .avatar > img {
		border-radius: 5px !important; 
	}
	.avatar.online:before {
		background-color: $success;
	}
	.avatar.avatar-xxl {
		width: 5rem;
		height: 5rem;
		line-height: 5rem;
		font-size: 1.5rem;
	}
	
	.avatar {
		.avatar-badge {
			position: absolute;
			inset-block-start: -4%;
			inset-inline-end: -0.375rem;
			width: 1.4rem;
			height: 1.4rem;
			font-size: 0.625rem;
			border: 2px solid $white;
			border-radius: 50% !important;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&.online,&.offline {
			&:before {
				position: absolute;
				content: "";
				width: 0.5rem;
				height: 0.5rem;
				border-radius: 50%;
				inset-inline-end: 0;
				inset-block-end: 0;
				border: 2px solid $white !important;
				z-index: 1;
			}
		}
		&.online:before {
			background-color: $success;
		}
		&.offline:before {
			background-color: $light;
		}
		&.avatar-xs {
			width: 1.25rem;
			height: 1.25rem;
			line-height: 1.25rem;
			font-size: 0.65rem;
			.avatar-badge {
				padding: 0.25rem;
				width:  1rem;
				height: 1rem;
				line-height: 1rem;
				font-size: 0.5rem;
				inset-block-start: -25%;
				inset-inline-end: -0.5rem;
			}
		}
		&.avatar-sm {
			width: 1.75rem;
			height: 1.75rem;
			line-height: 1.75rem;
			font-size: 0.65rem;
			.avatar-badge {
				padding: 0.3rem;
				width:  1.1rem;
				height: 1.1rem;
				line-height: 1.1rem;
				font-size: 0.5rem;
				inset-block-start: -28%;
				inset-inline-end: -0.45rem;
			}
			&.online,&.offline {
				&:before {
					width: 0.5rem;
					height: 0.5rem;
				}
			}    
		}
		&.avatar-md {
			width: 2.5rem;
			height: 2.5rem;
			line-height: 2.5rem;
			font-size: 0.8rem;
			.avatar-badge {
				padding: 0.4rem;
				width:  1.2rem;
				height: 1.2rem;
				line-height: 1.2rem;
				font-size: 0.65rem;
				inset-block-start: -6%;
				inset-inline-end: -13%;
			}
			&.online,&.offline {
				&:before {
					width: 0.75rem;
					height: 0.75rem;
				}
			}    
			svg {
				width: 1.5rem;
				height: 1.5rem;
			}
		}
		&.avatar-lg {
			width: 3rem;
			height: 3rem;
			line-height: 3rem;
			font-size: 1rem;
			.avatar-badge {
				inset-block-start: -15%;
				inset-inline-end: -0.25%;
			}
			&.online,&.offline {
				&:before {
					width: 0.8rem;
					height: 0.8rem;
				}
			}    
			svg {
				width: 1.8rem;
				height: 1.8rem;
			}
		}
		&.avatar-xl {
			width: 4rem;
			height: 4rem;
			line-height: 4rem;
			font-size: 1.25rem;
			.avatar-badge {
				inset-block-start: -8%;
				inset-inline-end: -0.2%;
			}
			&.online,&.offline {
				&:before {
					width: 0.95rem;
					height: 0.95rem;
				}
			}    
		}
		&.avatar-xxl {
			width: 5rem;
			height: 5rem;
			line-height: 5rem;
			font-size: 1.5rem;
			.avatar-badge {
				inset-block-start: -4%;
				inset-inline-end: 0rem;
			}
			&.online,&.offline {
				&:before {
					width: 1.05rem;
					height: 1.05rem;
					inset-block-end: 0.25rem;
				}
			}    
		}
		&.avatar-xxxl {
			width: 6rem;
			height: 6rem;
			line-height: 6rem;
			font-size: 1.75rem;
			.avatar-badge {
				inset-block-start: -4%;
				inset-inline-end: 0rem;
			}
			&.online,&.offline {
				&:before {
					width: 1.05rem;
					height: 1.05rem;
					inset-block-end: 0.25rem;
				}
			}    
		}
	}
	.bg-primary {
		.avatar-title {
			background: $primary;
			border-radius: 50%;
		}
	}
	.bg-success {
		.avatar-title {
			background: $success;
		}
	}
	.bg-danger {
		.avatar-title {
			background: $danger;
		}
	}
	.bg-warning {
		.avatar-title {
			background: $warning;
		}
	}
	.bg-info {
		.avatar-title {
			background: $info
		}
	}
	.border-thumb {
		.avatar-title {
			border-radius: 4px;
		}
	}
	.avatar-radius-0 {
		.avatar-title {
			border-radius: 0 !important;
		}
	}
	.avatar-rounded {
		.avatar-title {
			border-radius: 50%;
		}
	}
	.bg-soft-secondary {
		.avatar-title {
			background: rgba($secondary,0.5);
		}
		
	}
	.bg-soft-primary {
		.avatar-title {
			background: rgba($primary,0.5);
		}
		
	}
	.bg-soft-danger {
		.avatar-title {
			background: rgba($danger,0.5);
		}
		
	}
	.bg-soft-success {
		.avatar-title {
			background: rgba($success,0.5);
		}
		
	}
	.bg-soft-info {
		.avatar-title {
			background: rgba($info,0.5);
		}
		
	}
	
	.avatar-list-stacked {
		padding: 0;
		&.avatar-group-overlapped {
			.avatar {
				margin-right: -.875rem;
				&:hover{
					z-index:1;
				}
			}
		}	
		&.avatar-group-lg {
			.avatar { 
				width: 3.25rem;
				height: 3.25rem;
				>.initial-wrap {
					font-size: .95rem;
				}
			}
			&.avatar-group-overlapped .avatar {
				margin-right: -1rem;
			}	
		}
		&.avatar-group-sm {
			.avatar { 
				width: 2rem;
				height: 2rem;
				>.initial-wrap {
					font-size: .6rem;
				}
			}
			&.avatar-group-overlapped .avatar {
				margin-right: -0.625rem;
			}	
		}
		.avatar {
			margin-inline-end: -0.875rem !important;
			border: 1px solid rgba(0,0,0,0.05);
			vertical-align: middle;
			transition: transform ease 200ms;
			&:last-child {
				margin-inline-end: 0 !important;
			}
			&:hover {
				z-index: 1;
				transform: translateY(-0.188rem);
			}
		}
	}
	.border-thumb {
		.avatar-list-stacked {
			.avatar {
				border-radius: 5px;
				img {
					border-radius: 5px;
				}
			}
		}
	}	
}