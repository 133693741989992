.rtl{
	.alertify {
		.ajs-footer {
			border-top: 1px solid #eee;
			.ajs-buttons {
				.ajs-button {
					font-weight: 500;
					background-color: transparent;
					color: #000;
					border: 0;
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					&.ajs-ok {
						color: $primary;
					}
				}
				&.ajs-primary {
					text-align: left;
				}
			}
		}
		.ajs-body {
			.ajs-content {
				.ajs-input {
					display: block;
					width: 100%;
					padding: 8px;
					margin: 4px;
					border-radius: 2px;
					border: 1px solid #ccc;
					&:focus-visible {
						outline: 0;
					}
				}
			}
		}
		.ajs-commands {
			left: 4px;
			right: auto;
			margin: -14px 24px 0 0;
		}
		.ajs-header {
			border-bottom: 1px solid #eee;
		}
	}
	.alertify-notifier {
		.ajs-message {
			background-color: $primary;
			border-color: $primary;
			color: $white;
			text-shadow: none!important;
			&.ajs-success {
				background-color: $success-900;
				border-color: $success-900;
			}
			&.ajs-error {
				background-color: $danger;
				border-color: $danger;
			}
			&.ajs-warning {
				background-color: #ffbf53;
				border-color: #ffbf53;
			}
		}
		&.ajs-right {
			left: 10px;
			right: auto;
			.ajs-message {
				left: -320px;
				right: auto;
				&.ajs-visible {
					left: 290px;
					right: auto;
				}
			}
		}
		&.ajs-left {
			right: 10px;
			left: auto;
			.ajs-message {
				right: -300px;
				left: auto;
				&.ajs-visible {
					right: 0;
					left: auto;
				}
			}
		}
	}
	body[data-layout-mode=dark] {
		.alertify {
			.ajs-dialog {
				background-color: #313533;
			}
			.ajs-body {
				color: #adb5bd;
				.ajs-content {
					.ajs-input {
						color: #adb5bd;
						background-color: #363a38;
						border: 1px solid #3b403d;
					}
				}
			}
			.ajs-footer {
				.ajs-buttons {
					.ajs-button {
						color: #adb5bd;
						&.ajs-ok {
							color: $primary;
						}
					}
				}
			}
		}
	}
	body[data-layout-mode=dark] .alertify .ajs-footer,
	body[data-layout-mode=dark] .alertify .ajs-header {
		color: #ced4da;
		background-color: #313533;
		border-color: #3b403d;
	}
	.swal2-actions {
		button {
			margin-left: 10px;
		}
	}
	.swal2-title {
		font-size: 22px  !important;
	}	
}