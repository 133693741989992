.ltr{
    .faq-card {
        .card {
            border-color: $light;
            @include rounded(3px);
            box-shadow: none;
            margin-bottom: 20px;
            overflow: hidden;
            .card-header {
                background-color: $white;
                position: relative;
                @include margin-padding(null, 20px);
                h4 {
                    margin-bottom: 0;
                    a {
                        color: $secondary-900;
                        display: block;
                        font-size: $font-size-20;
                        font-weight: $font-weight-normal;
                        padding-right: 60px;
                        position: relative;
                        &:after {
                            font-family: FontAwesome;
                            font-size: $font-size-19;
                            font-weight: $font-weight-normal;
                            @include position(absolute,null,0,null,null);
                        }
                        &.collapsed{
                            &:after {
                                content: "\f067";
                            }
                        }
                        &:not(.collapsed){
                            &:after {
                                content: "\f068";
                            }
                        }
                    }
                }
            }
        }
    }
}